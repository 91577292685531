import React from "react";
import { Helmet } from "react-helmet-async";
import Footer from "./Footer";

const TermsConditions = () => {
  return (
    <div className="pageWrapper">
          <Helmet>
            <title>Site Terms & Conditions | Calmex</title>
          </Helmet>
      <section
        style={{ zIndex: 9999, width: "100%" }}
        className="mt-48 absolute bg-black"
      >
        <div className="bg-black text-white background-wheel">
          <div className="container mx-auto px-4 py-12">
            <div className="w-full">
              <h1 className="mb-12 text-5xl/[58px] font-inter lg:text-6xl/[70px] font-light tracking-tight text-center">
                Site Terms & Conditions
              </h1>
              <div className="text-left font-inter">
                <h2 className="text-2xl font-bold mb-8">1. Introduction</h2>
                <p className="mb-8">
                  Welcome to the homepage for Calmex (hereafter referred to as "Calmex," "we," "us," or "our"). These Terms & Conditions ("Terms") govern your use of our website, calmex.com (the "Site"). By accessing or using the Site, you agree to comply with and be bound by these Terms.
                </p>
                <h2 className="text-2xl font-bold mb-8">2. Acceptance of Terms</h2>
                <p className="mb-8">
                  By using our Site, you accept these Terms in full. If you disagree with these Terms or any part of these Terms, you must not use our Site.
                </p>
                <h2 className="text-2xl font-bold mb-8">3. Changes to Terms</h2>
                <p className="mb-8">
                  We reserve the right to modify these Terms at any time. Changes will be effective immediately upon posting on the Site. Your continued use of the Site following the posting of changes will constitute your acceptance of such changes.
                </p>
                <h2 className="text-2xl font-bold mb-8">4. Intellectual Property</h2>
                <p className="mb-8">
                  All content on the Site, including text, graphics, logos, images, and software, is the property of Calmex or its content suppliers and protected by intellectual property laws. You may not reproduce, distribute, or create derivative works from any content without our express written permission.
                </p>
                <h2 className="text-2xl font-bold mb-8">5. User Conduct</h2>
                <p className="mb-8">
                  You agree to use the Site only for lawful purposes. You must not:
                </p>
                <ul className="list-disc list-inside mb-8">
                  <li>Engage in any activity that could harm or disrupt the Site.</li>
                  <li>Post or transmit any content that is unlawful, defamatory, obscene, or harmful.</li>
                  <li>Use the Site to impersonate any person or entity or falsely state your affiliation with any person or entity.</li>
                </ul>
                <h2 className="text-2xl font-bold mb-8">6. Product Information</h2>
                <p className="mb-8">
                  We strive to ensure that the information about our products is accurate and up-to-date. However, we do not warrant that product descriptions or other content on the Site is accurate, complete, or current. We reserve the right to correct any errors or omissions and to change or update information at any time without prior notice.
                </p>
                <h2 className="text-2xl font-bold mb-8">7. Disclaimer of Warranties</h2>
                <p className="mb-8">
                  The Site is provided "as is" and "as available" without warranties of any kind, either express or implied. We disclaim all warranties, including but not limited to implied warranties of merchantability, fitness for a particular purpose, and non-infringement.
                </p>
                <h2 className="text-2xl font-bold mb-8">8. Limitation of Liability</h2>
                <p className="mb-8">
                  To the fullest extent permitted by law, Calmex shall not be liable for any indirect, incidental, special, consequential, or punitive damages, or any loss of profits or revenues, whether incurred directly or indirectly, or any loss of data, use, goodwill, or other intangible losses, resulting from:
                </p>
                <ul className="list-disc list-inside mb-8">
                  <li>Your use or inability to use the Site;</li>
                  <li>Any unauthorized access to or use of our servers and/or any personal information stored therein;</li>
                  <li>Any interruption or cessation of transmission to or from the Site;</li>
                  <li>Any bugs, faulty code, defacement, unauthorized content or similar that may be transmitted to or through the Site by a third party;</li>
                  <li>Any errors or omissions in any content or for any loss or damage incurred as a result of the use of any content posted, emailed, transmitted, or otherwise made available through the Site.</li>
                </ul>
                <h2 className="text-2xl font-bold mb-8">9. Indemnification</h2>
                <p className="mb-8">
                  You agree to indemnify, defend, and hold harmless Calmex, its affiliates, officers, directors, employees, agents, licensors, and suppliers from and against all claims, losses, expenses, damages, and costs, including reasonable attorneys' fees, arising out of or relating to your use of the Site or any violation of these Terms.
                </p>
                <h2 className="text-2xl font-bold mb-8">10. Governing Law</h2>
                <p className="mb-8">
                  These Terms shall be governed by and construed in accordance with the laws of the jurisdiction in which Calmex operates, without regard to its conflict of law principles. You agree to submit to the exclusive jurisdiction of the courts located in that jurisdiction for the resolution of any disputes arising out of or relating to these Terms or the Site.
                </p>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </section>
    </div>
  );
};

export default TermsConditions;
