import React from "react";
import Footer from "../Footer";

const IceCreamWithWildAbalone = () => {
  return (
    <div className="pageWrapper">
      <section
        style={{ zIndex: 9999, width: "100%" }}
        className="mt-48 absolute bg-black"
      >
        <div className="bg-black text-white background-wheel">
          <div className="container mx-auto px-4 py-12 flex flex-wrap items-center justify-between">
            <div className="w-full">
              {/* Upper header pink text: RECIPE */}
              <h2 className="text-2xl font-bold text-pink-300 tracking-wider text-center">
                RECIPE
              </h2>
              <h1 className="mb-12 text-5xl/[58px] font-inter lg:text-6xl/[70px] font-light tracking-tight text-center">
                Ice Cream With Wild Abalone
              </h1>
            </div>
            <article
              id="post-453"
              className="post-453 post type-post status-publish format-standard hentry category-uncategorized font-inter"
            >
              <div className="post-thumbnail"></div>
              <div className="entry-content">
                <figure className="wp-block-image size-large">
                  <img
                    decoding="async"
                    loading="lazy"
                    width="1024"
                    height="819"
                    src="/Untitled-145-1024x819.jpg"
                    alt=""
                    className="wp-image-454"
                    srcSet="/Untitled-145-1024x819.jpg 1024w, /Untitled-145-300x240.jpg 300w, /Untitled-145-768x614.jpg 768w, /Untitled-145.jpg 1350w"
                    sizes="(max-width: 1024px) 100vw, 1024px"
                  />
                </figure>
                <p>
                  The humid, hot summer months are here and that means limited-edition ice cream flavors at your local 7/11 or grocery stores! In Asia, we embrace cold treats like ice cream, shaved ice, boba, cold noodles, etc because they are easy to eat and feels refreshing going down your throat. We try to stay away from hot foods during this time and even go to lengths to avoid cooking near a hot stove.
                </p>
                <p>
                  In Asia, there are many collaborations with known ice cream brands like Haagen Daz, Nestle, and Lotte to create unique and sometimes very strange ice cream flavors to create buzz and encourage people to post and share about it.
                </p>
                <p>
                  Just to name a few; soy sauce, tomato-dashi, eel sauce, shark fin, and even seaweed have made it into ice cream flavors in the past and found that people are eager to at least try the flavor once.
                </p>
                <p>
                  Even if the flavors show they do not do well for profit, they are sometimes a limited-time campaign and is yet another way to create talk about the brand that decided to try something new!
                </p>
                <p>
                  Although we cannot survive these hot summer months on just ice cream studies show, “there is even research to suggest that eating ice cream can make a person more active and reduce mental irritation” <a href="https://www.dw.com/en/japans-craze-for-ice-creams/a-57826217">https://www.dw.com/en/japans-craze-for-ice-creams/a-57826217</a>
                </p>
                <p>
                  Therefore we had an idea for ice cream that one of our followers jokingly suggested, (@shisespicelab on IG) abalone ice cream…and we thought, “Why not?” Ice cream is naturally made up of cream and a lot of sugar but if we added some protein, the juice of our canned abalone it would be a much healthier treat if you made ice cream at home with abalone!
                </p>
                <figure className="wp-block-image size-large">
                  <img
                    decoding="async"
                    loading="lazy"
                    width="1024"
                    height="819"
                    src="/Untitled-147-1024x819.jpg"
                    alt=""
                    className="wp-image-455"
                    srcSet="/Untitled-147-1024x819.jpg 1024w, /Untitled-147-300x240.jpg 300w, /Untitled-147-768x614.jpg 768w, /Untitled-147.jpg 1350w"
                    sizes="(max-width: 1024px) 100vw, 1024px"
                  />
                </figure>
                <p>We bought our ice cream, but if you choose to make some at home, the recipe is below!</p>
                <p><strong>Ingredients</strong>:</p>
                <p>1 Can Calmex Wild Abalone</p>
                <p>2 Cups Heavy Cream</p>
                <p>½ Cup Calmex Wild Abalone Juice</p>
                <p>1 Tablespoon of Sugar</p>
                <p>½ Tablespoon of Salt</p>
                <p>5 Egg Yolks</p>
                <p>(Toppings Optional)</p>
                <p>Thinly sliced or diced wild abalone</p>
                <p>Soysauce sugar glaze</p>
                <p>Salmon Egg Roe</p>
                <p>Seaweed Flakes</p>
                <p>Mini Cone</p>
                <p><strong>Directions</strong>:</p>
                <p>Whisk the cream, abalone juice, milk, sugar, and 1/2 teaspoon salt in a medium saucepan and bring to a simmer over medium heat.</p>
                <p>Beat egg yolks in a separate bowl and slowly whisk 1 cup of the cream mixture above into the egg yolk bowl.</p>
                <p>Pour the combined mixture back into the saucepan stirring constantly until the mixture thickens about 4 minutes.</p>
                <p>Take the saucepan off the heat and transfer mixture into a regular bowl stirring more until mixture reaches room temperature. Put plastic wrap directly onto cooled custard mixture before leaving in the freezer for 3-5 hours.</p>
                <p><strong>Toppings:</strong></p>
                <p>Katsu Sauce (to glaze over ice cream)</p>
                <p>Chop or dice wild abalone bits to sprinkle on top of your ice cream</p>
                <p>Salmon roe and seaweed flakes as toppings</p>
                <p>And any other creative toppings you can think of!</p>
                <p>Serve in a cocktail glass for your guests outside as a nice cold, sensational appetizer this summer!</p>
                <figure className="wp-block-image size-large">
                  <img
                    decoding="async"
                    loading="lazy"
                    width="1024"
                    height="819"
                    src="/Untitled-148-1024x819.jpg"
                    alt=""
                    className="wp-image-456"
                    srcSet="/Untitled-148-1024x819.jpg 1024w, /Untitled-148-300x240.jpg 300w, /Untitled-148-768x614.jpg 768w, /Untitled-148.jpg 1350w"
                    sizes="(max-width: 1024px) 100vw, 1024px"
                  />
                </figure>
                <figure className="wp-block-embed">
                  <div className="wp-block-embed__wrapper">
                    <a href="https://asia.nikkei.com/Business/Companies/Seaweed-infused-ice-cream-tickles-Japan-s-umami-tastebuds">
                      https://asia.nikkei.com/Business/Companies/Seaweed-infused-ice-cream-tickles-Japan-s-umami-tastebuds
                    </a>
                  </div>
                </figure>
              </div>
              <footer className="entry-footer"></footer>
            </article>
          </div>
        </div>
        <Footer />
      </section>
    </div>
  );
};

export default IceCreamWithWildAbalone;
