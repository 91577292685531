import React from "react";
import Footer from "../Footer";

const WildAbaloneSpringRoll = () => {
  return (
    <div className="pageWrapper">
      <section
        style={{ zIndex: 9999, width: "100%" }}
        className="mt-48 absolute bg-black"
      >
        <div className="bg-black text-white background-wheel">
          <div className="container mx-auto px-4 py-12 flex flex-wrap items-center justify-between">
            <div className="w-full">
              {/* Upper header pink text: RECIPE */}
              <h2 className="text-2xl font-bold text-pink-300 tracking-wider text-center">
                RECIPE
              </h2>
              <h1 className="mb-12 text-5xl/[58px] font-inter lg:text-6xl/[70px] font-light tracking-tight text-center">
                Wild Abalone Spring Roll
              </h1>
            </div>
            <article
              id="post-477"
              className="post-477 post type-post status-publish format-standard hentry category-uncategorized font-inter"
            >
              <div className="post-thumbnail"></div>
              <div className="entry-content">
                <figure className="wp-block-image size-large">
                  <img
                    decoding="async"
                    loading="lazy"
                    width="819"
                    height="1024"
                    src="/Untitled-247-819x1024.jpg"
                    alt=""
                    className="wp-image-479"
                    srcSet="/Untitled-247-819x1024.jpg 819w, /Untitled-247-240x300.jpg 240w, /Untitled-247-768x960.jpg 768w, /Untitled-247.jpg 1080w"
                    sizes="(max-width: 819px) 100vw, 819px"
                  />
                </figure>
                <p>
                  Ready to spring into action with our protein-packed, wild abalone spring roll? This versatile rice paper allows practically any recipe to turn into a handheld snack. No heat required, this is the Asian style of salad wraps is a crowd favorite during hot summer months! Although this rice paper may be intimidating to use for cooking, we can show you an easy way to handle it with care. With fresh ingredients and healthy protein, this will be your favorite quick appetizer!
                </p>
                <p><strong>Ingredients</strong>:</p>
                <p>1 Can Calmex Wild Abalone</p>
                <p>1 Pack of Spring Roll Paper</p>
                <p>Seaweed Salad</p>
                <p>1 Avocado</p>
                <p>1 Cucumber</p>
                <p>1 Carrot</p>
                <p>Kewpie Mayo</p>
                <p>Peanut Sauce</p>
                <figure className="wp-block-image size-large">
                  <img
                    decoding="async"
                    loading="lazy"
                    width="819"
                    height="1024"
                    src="/Untitled-246-819x1024.jpg"
                    alt=""
                    className="wp-image-478"
                    srcSet="/Untitled-246-819x1024.jpg 819w, /Untitled-246-240x300.jpg 240w, /Untitled-246-768x960.jpg 768w, /Untitled-246.jpg 1080w"
                    sizes="(max-width: 819px) 100vw, 819px"
                  />
                </figure>
                <p><strong>Directions</strong>:</p>
                <p>Open up a fresh can of Calmex and slice the whole abalone into thin slices.</p>
                <p>Prep your cucumber and carrots into desired slices as well, thin or cubed your choice!</p>
                <p>
                  When moistening your spring roll paper, fill a mug of cold water and gently fold your rice paper into the cup and make sure to moisten all around. In our TikTok video, you can see we filled a measuring cup to get the sheet wet.
                </p>
                <p>(quicker tip: run your spring roll paper under the faucet until the entire sheet has been wet)</p>
                <p>Do not soak your spring roll paper until it gets soggy because it will be harder to roll when all the contents are inside.</p>
                <p>
                  Lay your moistened spring roll sheet on a flat surface and add Calmex Wild Abalone in the middle first. We put our contents in vertically so it can be easy to fold later. Add as many as you would like but leave some room at the edges so it will be easier to fold.
                </p>
                <p>
                  Add your seaweed salad, avocado, cucumber, carrots, and any other toppings you would like. Fresh romaine or arugula would be a great addition as well!
                </p>
                <p>Drizzle some kewpie mayo on top for a creamy texture.</p>
                <p>Fold the top edges inward into the ingredients then roll the right side of the paper over and keep rolling until everything is enclosed in the rice paper.</p>
                <p>If you need more help making this recipe, please watch our TikTok to see how we rolled our spring rolls!</p>
                <p>Dip in your favorite peanut or spice sauce and enjoy your handheld snack!</p>
              </div>
              <footer className="entry-footer"></footer>
            </article>
          </div>
        </div>
        <Footer />
      </section>
    </div>
  );
};

export default WildAbaloneSpringRoll;
