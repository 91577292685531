import React from "react";
import { Helmet } from "react-helmet-async";
import Footer from "./Footer";

const RecipeListing = () => {
  return (
    <div className="pageWrapper">
      <Helmet>
        <title>Recipes | Calmex</title>
      </Helmet>
      <section
        style={{ zIndex: 9999, width: "100%" }}
        className="mt-48 absolute bg-black"
      >
        <div className="bg-black text-white background-wheel-sm md:pb-24">
          <div className="container mx-auto px-4 py-12">
            <div className="w-full">
              {/* overlap-b.png */}
              <div className="text-center">
              <img src="/overlap-c.png" className="mx-auto"/>
              </div>
              <h1 className="mb-12 text-5xl/[58px] font-inter lg:text-6xl/[70px] font-light tracking-tight text-center">
                Recipes
              </h1>
              <div className="text-center">
                <h2 className="text-2xl font-bold tracking-wider mb-8"></h2>
                <p className="mb-8 font-inter">
                  Try these delicious recipes featuring Calmex products.
                </p>

                <div className="recipeListing text-left max-w-lg mx-auto">
                {/* 
                  <Route path="/uncategorized/abalone-cream-stew" element={<RecipeComponents.AbaloneCreamStew />} />
                  <Route path="/uncategorized/abalone-rice" element={<RecipeComponents.AbaloneRice />} />
                  <Route path="/uncategorized/ice-cream-with-wild-abalone" element={<RecipeComponents.IceCreamWithWildAbalone />} />
                  <Route path="/uncategorized/sauteed-abalone-shiitake" element={<RecipeComponents.SauteedAbaloneShiitake />} />
                  <Route path="/uncategorized/spooky-spaghetti-squash-abalone-pastas" element={<RecipeComponents.SpookySpaghettiSquashAbalonePastas />} />
                  <Route path="/uncategorized/wild-abalone-bagel" element={<RecipeComponents.WildAbaloneBagel />} />
                  <Route path="/uncategorized/wild-abalone-caesar-salad" element={<RecipeComponents.WildAbaloneCaesarSalad />} />
                  <Route path="/uncategorized/wild-abalone-gravy" element={<RecipeComponents.WildAbaloneGravy />} />
                  <Route path="/uncategorized/wild-abalone-spring-roll" element={<RecipeComponents.WildAbaloneSpringRoll />} />
                */}
                  
                  <div className="recipeListingItem">
                    <a href="/uncategorized/abalone-cream-stew" className="inline">
                      <h3 className="
                        text-2xl font-bold tracking-wider
                        hover:text-pink-300 transition-colors
                        inline
                      ">Abalone Cream Stew</h3>
                    </a>
                  </div>
                  
                  <div className="recipeListingItem">
                    <a href="/uncategorized/abalone-rice" className="inline">
                      <h3 className="
                        text-2xl font-bold tracking-wider
                        hover:text-pink-300 transition-colors
                        inline
                      ">Abalone Rice</h3>
                    </a>
                  </div>
                  
                  <div className="recipeListingItem">
                    <a href="/uncategorized/ice-cream-with-wild-abalone" className="inline">
                      <h3 className="
                        text-2xl font-bold tracking-wider
                        hover:text-pink-300 transition-colors
                        inline
                      ">Ice Cream with Wild Abalone</h3>
                    </a>
                  </div>
                  
                  <div className="recipeListingItem">
                    <a href="/uncategorized/sauteed-abalone-shiitake" className="inline">
                      <h3 className="
                        text-2xl font-bold tracking-wider
                        hover:text-pink-300 transition-colors
                        inline
                      ">Sauteed Abalone Shiitake</h3>
                    </a>
                  </div>
                  
                  <div className="recipeListingItem">
                    <a href="/uncategorized/spooky-spaghetti-squash-abalone-pastas" className="inline">
                      <h3 className="
                        text-2xl font-bold tracking-wider
                        hover:text-pink-300 transition-colors
                        inline
                      ">Spooky Spaghetti Squash Abalone Pastas</h3>
                    </a>
                  </div>
                  
                  <div className="recipeListingItem">
                    <a href="/uncategorized/wild-abalone-bagel" className="inline">
                      <h3 className="
                        text-2xl font-bold tracking-wider
                        hover:text-pink-300 transition-colors
                        inline
                      ">Wild Abalone Bagel</h3>
                    </a>
                  </div>
                  
                  <div className="recipeListingItem">
                    <a href="/uncategorized/wild-abalone-caesar-salad" className="inline">
                      <h3 className="
                        text-2xl font-bold tracking-wider
                        hover:text-pink-300 transition-colors
                        inline
                      ">Wild Abalone Caesar Salad</h3>
                    </a>
                  </div>
                  
                  <div className="recipeListingItem">
                    <a href="/uncategorized/wild-abalone-gravy" className="inline">
                      <h3 className="
                        text-2xl font-bold tracking-wider
                        hover:text-pink-300 transition-colors
                        inline
                      ">Wild Abalone Gravy</h3>
                    </a>
                    </div>

                  </div>

              </div>
            </div>

          </div>
        </div>
        <Footer />
      </section>
    </div>
  );
};

export default RecipeListing;
