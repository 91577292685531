import React from "react";
import Footer from "../Footer";

const SauteedAbaloneShiitake = () => {
  return (
    <div className="pageWrapper">
      <section
        style={{ zIndex: 9999, width: "100%" }}
        className="mt-48 absolute bg-black"
      >
        <div className="bg-black text-white background-wheel">
          <div className="container mx-auto px-4 py-12 flex flex-wrap items-center justify-between">
            <div className="w-full">
              {/* Upper header pink text: RECIPE */}
              <h2 className="text-2xl font-bold text-pink-300 tracking-wider text-center">
                RECIPE
              </h2>
              <h1 className="mb-12 text-5xl/[58px] font-inter lg:text-6xl/[70px] font-light tracking-tight text-center">
                Sautéed Abalone + Shiitake
              </h1>
            </div>
            <article
              id="post-482"
              className="post-482 post type-post status-publish format-standard hentry category-uncategorized font-inter"
            >
              <div className="post-thumbnail"></div>
              <div className="entry-content">
                <p>
                  The mid-Autumn festival calls for mooncakes and delicious food to share with the whole family! Calling it the Asian Thanksgiving is a stretch but it sure is a time to reunite with family, honor the moon and celebrate the harvest of the season. All kinds of food can be served during this festival. However, the most traditional foods are:
                </p>
                <ul>
                  <li>Duck</li>
                  <li>Hairy Crab</li>
                  <li>Pomelos</li>
                  <li>Pears</li>
                  <li>Pumpkin</li>
                  <li>Persimmon</li>
                </ul>
                <p>
                  Most of the produce that are served during this time are in season and they resemble the moon!
                </p>
                <p>
                  Although wild abalone is not typically served during this time, it will surely excite your guests. Serve it as an appetizer or side dish so there is enough to go around the dinner table. In honor of Mid-Autumn Festival we cooked up a healthy shiitake abalone appetizer dish that will impress your family members! Be sure to still serve duck or hairy crab as the main course.
                </p>
                <figure className="wp-block-image size-large">
                  <img
                    decoding="async"
                    loading="lazy"
                    width="819"
                    height="1024"
                    src="/Untitled-265-819x1024.jpg"
                    alt=""
                    className="wp-image-485"
                    srcSet="/Untitled-265-819x1024.jpg 819w, /Untitled-265-240x300.jpg 240w, /Untitled-265-768x960.jpg 768w, /Untitled-265.jpg 1080w"
                    sizes="(max-width: 819px) 100vw, 819px"
                  />
                </figure>
                <p><strong>Sautéed</strong> <strong>Abalone + Shiitake</strong></p>
                <p>Ingredients:</p>
                <ul>
                  <li>1 Can Calmex Wild Abalone</li>
                  <li>Shiitake Mushrooms sliced</li>
                  <li>1 Enoki Mushroom package</li>
                  <li>1 Ginger</li>
                  <li>5-6 boiled chestnut toppings</li>
                  <li>Green onion toppings</li>
                  <li>1 Tablespoon Sesame Oil</li>
                  <li>2 Tablespoon Soy sauce</li>
                  <li>Hoisen Sauce (optional)</li>
                </ul>
                <figure className="wp-block-image size-large">
                  <img
                    decoding="async"
                    loading="lazy"
                    width="819"
                    height="1024"
                    src="/Untitled-266-819x1024.jpg"
                    alt=""
                    className="wp-image-484"
                    srcSet="/Untitled-266-819x1024.jpg 819w, /Untitled-266-240x300.jpg 240w, /Untitled-266-768x960.jpg 768w, /Untitled-266.jpg 1080w"
                    sizes="(max-width: 819px) 100vw, 819px"
                  />
                </figure>
                <p>Directions:</p>
                <p>Heat your pan with 1 tablespoon of sesame oil.</p>
                <p>Peel and slice ginger into small slices and sautee in pan until fragrant.</p>
                <p>Cut stems off the shiitake mushroom and cut into thin slices. Add to pan and mix well.</p>
                <p>Cut off the end piece of the enoki mushroom stalk and add to pan.</p>
                <p>Pour 2 tablespoons of soy sauce into the mixture.</p>
                <p>Leave your sliced Calmex abalone for last and briefly sautee with the rest of the contents.</p>
                <p>Lastly, garnish the dish with chestnuts and green onion on top and enjoy with more fresh abalone on top!</p>
                <p>Optional: Drizzle hoisin sauce to make the dish a little sweeter.</p>
                <figure className="wp-block-image size-large">
                  <img
                    decoding="async"
                    loading="lazy"
                    width="819"
                    height="1024"
                    src="/Untitled-264-819x1024.jpg"
                    alt=""
                    className="wp-image-483"
                    srcSet="/Untitled-264-819x1024.jpg 819w, /Untitled-264-240x300.jpg 240w, /Untitled-264-768x960.jpg 768w, /Untitled-264.jpg 1080w"
                    sizes="(max-width: 819px) 100vw, 819px"
                  />
                </figure>
              </div>
              <footer className="entry-footer"></footer>
            </article>
          </div>
        </div>
        <Footer />
      </section>
    </div>
  );
};

export default SauteedAbaloneShiitake;
