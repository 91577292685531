import React, { useState } from 'react';
import './Header.css';
import { Link } from 'react-router-dom';

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [wheelImage, setWheelImage] = useState('/calmex-brandtype-wheel-bw-downscaled.png'); // static image by default
  const [isSpinning, setIsSpinning] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleClick = () => {
    if (!isSpinning) {
      // Toggle between static and animated images on click
      setWheelImage('/spin-wheel.png');
      setIsSpinning(true);

      // Set timeout to switch back to static image after 4 seconds
      setTimeout(() => {
        setWheelImage('/calmex-brandtype-wheel-bw-downscaled.png');
        setIsSpinning(false);
      }, 2250);
    }
  };

  return (
    <div className='headerContainer'>
      <header className="header">
        <div className="logo">
          <Link to="/">
            <img src="/calmex-brandtype-bw.png" alt="Calmex" />
          </Link>
        </div>
        <div className="secondary-logo">
            <img src={wheelImage} className='shipWheel' alt="Calmex Ship Wheel Logo" onClick={handleClick} />
        </div>
        <nav className={`nav ${isMenuOpen ? 'nav--open' : ''}`}>
          <a href="/">PRODUCTS</a>
        </nav>
        <button className="menu-button" onClick={toggleMenu}>
          {isMenuOpen ? 'Close' : 'Menu'}
        </button>
      </header>
    </div>
  );
};

export default Header;
