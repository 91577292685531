import React from 'react';
import { Link } from 'react-router-dom';

const ScrollToTopLink = ({ to, className, children }) => {
  const handleClick = () => {
    window.scrollTo(0, 0);
  };

  return (
    <Link to={to} className={className} onClick={handleClick}>
      {children}
    </Link>
  );
};

export default ScrollToTopLink;
