import React from 'react';
import ScrollToTopLink from './components/ScrollToTopLink';
import classNames from 'classnames'; // Import classNames utility

const Footer = ({ hideDivider }) => {
    return (
        <div className="footer">
          <div className="bg-black text-white py-4 pb-8">

            <div className={classNames("px-12 md:px-0 md:container mx-auto flex flex-wrap justify-between items-center mt-12", {'mt-0': hideDivider})}>
              {!hideDivider && <div className="w-full border-b border-orange-300 opacity-20 mt-12"></div>}
            {/* Right-aligned img */}
              <div className={classNames("flex flex-col md:flex-row md:justify-between md:items-end w-full mt-8")}>
              <ScrollToTopLink to="/">
                <img
                  src="/calmex-brandtype-wheel.png"
                  alt="CALMEX Logo"
                  className="mr-4 mb-12 md:mb-0 max-w-[200px]"
                />
              </ScrollToTopLink>

                <div className="md:container">
                  <div className="flex flex-wrap md:justify-end items-center space-x-4 text-lg">
                  <ScrollToTopLink to="/" className="hover:text-gray-300">
                      Home
                    </ScrollToTopLink>
                    <a href="/products/abalone" className="hover:text-gray-300">
                      Abalone
                    </a>
                    <a href="/recipes" className="hover:text-gray-300">
                      Recipes
                    </a>
                    <a href="/sustainability" className="hover:text-gray-300">
                      Sustainability
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div className="md:container mx-auto mt-4">
              <div className="px-12 md:px-0 flex justify-between">
                <div>
                  <p className="font-inter text-xs">© {new Date().getFullYear()} CALMEX</p>
                </div>
                <div className="flex justify-end gap-4 text-xs font-inter">
                  <a href="/terms-conditions" className="hover:text-gray-300">
                    Terms & Conditions
                  </a>
                  <a href="/sitemap.xml" className="hover:text-gray-300">
                    Sitemap
                  </a>
                </div>
              </div>
            </div>
            <div className="px-8 md:px-0 md:container mx-auto flex md:justify-end items-center">
              <div>
                <img src="/green-logo-halal-sm.png" 
                alt="Halal Logo" 
                className='h-20 md:h-28 md:w-28 mr-4 mt-4'
                />
              </div>
            </div>
          </div>
        </div>
    );
};

export default Footer;
