import React from "react";
import { Helmet } from "react-helmet-async";

import { Link } from "react-router-dom";
import MenuItem from "./MenuItem";
import Footer from "./Footer";

function HomePage() {
  return (
    <div className="pageWrapper">
      <Helmet>
        <title>Calmex | The Essence of Oceanic Luxury</title>
      </Helmet>
      <div className="splashContainer">
        <div className="splash">
          <h1 className="jumboHeaderText">The Essence of Oceanic Luxury</h1>
          {/* <p>This is a great place to tell your story and give your visitors more insight into who you are, what you do, and why you do it.</p> */}
          <div
            style={{
              marginTop: "88px",
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                width: "2px",
                height: "100px",
                backgroundColor: "#FFF",
                display: "inline-block",
                margin: "0 16px",
              }}
            ></div>
            <Link
              to="/products/abalone"
              style={{ color: "#FFF", textDecoration: "underline" }}
            >
              <MenuItem
                text={"MEXICAN ABALONE"}
                imgSrc="/abalone-1.png"
                style={{ display: "inline-block" }}
              />
            </Link>

            {/* White vertical dividing line */}
            <div
              style={{
                width: "2px",
                height: "100px",
                backgroundColor: "#FFF",
                display: "inline-block",
                margin: "0 16px",
              }}
            ></div>

            <Link
              to="/products/australian-pink-label"
              style={{ color: "#FFF", textDecoration: "underline" }}
            >
              <MenuItem
                text={"AUSTRALIAN ABALONE"}
                imgSrc="/abalone-2.png"
                style={{ display: "inline-block" }}
              />
            </Link>

            <div
              style={{
                width: "2px",
                height: "100px",
                backgroundColor: "#FFF",
                display: "inline-block",
                margin: "0 16px",
              }}
            ></div>

            <Link
              to="/products/top-shell"
              style={{ color: "#FFF", textDecoration: "underline" }}
            >
              <MenuItem
                text={"TOP SHELL"}
                imgSrc="/abalone-3.png"
                style={{ display: "inline-block" }}
              />
            </Link>

            {/* White vertical dividing line */}
            <div
              style={{
                width: "2px",
                height: "100px",
                backgroundColor: "#FFF",
                display: "inline-block",
                margin: "0 16px",
              }}
            ></div>
          </div>
        </div>
      </div>
      <div className="fadeDownToBlack"></div>
      <div className="background-wheel-sm">
        {/* <div>
          <div className="mt-24 container mx-auto">
            <h2 className="text-center text-white jumboHeaderTextSm">
              What The People Are Saying
            </h2>
            <p className="text-center text-white font-inter">
              Every day, more people are discovering the meaning of oceanic
              luxury. Join the experience and share your thoughts with the
              #calmex hashtag!
            </p>
          </div>
          <div className="container mx-auto px-4 py-12 font-inter">
            <div className="md:p-10 columns-1 md:columns-3 gap-6">
              <div className="bg-white dark:bg-gray-800 border-gray-200 dark:border-gray-800 p-4 rounded-xl border mb-6 break-inside-avoid">
                <div className="flex justify-between">
                  <div className="flex items-center">
                    <img
                      className="h-11 w-11 rounded-full"
                      src="pb-avatar-346475832323.png"
                    />
                    <div className="ml-1.5 text-sm leading-tight">
                      <span className="text-black dark:text-white font-bold block ">
                        David Yang
                      </span>
                      <span className="text-gray-500 dark:text-gray-400 font-normal block">
                        @DavidYang10
                      </span>
                    </div>
                  </div>
                </div>
                <p className="text-black dark:text-white block text-xl leading-snug mt-3">
                  Unique gift idea: instead of alcohol or candy for Mom and Dad,
                  try abalone! Delicious, packed with health benefits - they
                  loved it. Thanks Calmex!
                </p>
                <img
                  className="mt-2 rounded-2xl border border-gray-100 dark:border-gray-700"
                  src="/pb-133258253.png"
                />
              </div>

              <div className="bg-white dark:bg-gray-800 border-gray-200 dark:border-gray-800 p-4 rounded-xl border mb-6 break-inside-avoid">
                <div className="flex justify-between">
                  <div className="flex items-center">
                    <img
                      className="h-11 w-11 rounded-full"
                      src="pb-avatar-6389457209823.png"
                    />
                    <div className="ml-1.5 text-sm leading-tight">
                      <span className="text-black dark:text-white font-bold block ">
                        Divina in L.A. ✨
                      </span>
                      <span className="text-gray-500 dark:text-gray-400 font-normal block">
                        @sparkulgirlz
                      </span>
                    </div>
                  </div>
                </div>
                <p className="text-black dark:text-white block text-xl leading-snug mt-3">
                  So I just had Mexican abalone ceviche. OMG, yall. 😋
                </p>
              </div>

              <div className="bg-white dark:bg-gray-800 border-gray-200 dark:border-gray-800 p-4 rounded-xl border mb-6 break-inside-avoid">
                <div className="flex justify-between">
                  <div className="flex items-center">
                    <img
                      className="h-11 w-11 rounded-full"
                      src="pb-avatar-91735478.png"
                    />
                    <div className="ml-1.5 text-sm leading-tight">
                      <span className="text-black dark:text-white font-bold block ">
                        Jack Perry is EATING 🍔
                      </span>
                      <span className="text-gray-500 dark:text-gray-400 font-normal block">
                        @_Jackster55
                      </span>
                    </div>
                  </div>
                </div>
                <p className="text-black dark:text-white block text-xl leading-snug mt-3">
                  can't believe this backstage seafood buffet. wild grilled
                  scallops, garlic butter shrimp, wild mexican abalone, sashimi
                  station. holy-
                </p>
              </div>

              <div className="bg-white dark:bg-gray-800 border-gray-200 dark:border-gray-800 p-4 rounded-xl border mb-6 break-inside-avoid">
                <div className="flex justify-between">
                  <div className="flex items-center">
                    <img
                      className="h-11 w-11 rounded-full"
                      src="pb-avatar-142721467.png"
                    />
                    <div className="ml-1.5 text-sm leading-tight">
                      <span className="text-black dark:text-white font-bold block ">
                        Andrew Li
                      </span>
                      <span className="text-gray-500 dark:text-gray-400 font-normal block">
                        @royalecheff
                      </span>
                    </div>
                  </div>
                </div>
                <p className="text-black dark:text-white block text-xl leading-snug mt-3">
                  Our restaurant did add abalone appetizers and entrees to the
                  menu last year. Calmex is basically the best quality you can
                  get.
                </p>
                <img
                  className="mt-2 rounded-2xl border border-gray-100 dark:border-gray-700"
                  src="/pb-623558253.png"
                />
              </div>

              <div className="bg-white dark:bg-gray-800 border-gray-200 dark:border-gray-800 p-4 rounded-xl border mb-6 break-inside-avoid">
                <div className="flex justify-between">
                  <div className="flex items-center">
                    <img
                      className="h-11 w-11 rounded-full"
                      src="pb-avatar-2017145718.png"
                    />
                    <div className="ml-1.5 text-sm leading-tight">
                      <span className="text-black dark:text-white font-bold block">
                        Sarah Kim
                      </span>
                      <span className="text-gray-500 dark:text-gray-400 font-normal block">
                        @sarahkim
                      </span>
                    </div>
                  </div>
                </div>
                <p className="text-black dark:text-white block text-xl leading-snug mt-3">
                  Spent the afternoon hiking, ended up bringing some abalone in
                  a can for a snack. Best trail food ever, ready-to-eat protein
                  😆
                </p>
              </div>

              <div className="bg-white dark:bg-gray-800 border-gray-200 dark:border-gray-800 p-4 rounded-xl border mb-6 break-inside-avoid">
                <div className="flex justify-between">
                  <div className="flex items-center">
                    <img
                      className="h-11 w-11 rounded-full"
                      src="pb-avatar-13546724334.png"
                    />
                    <div className="ml-1.5 text-sm leading-tight">
                      <span className="text-black dark:text-white font-bold block ">
                        PattyPatty
                      </span>
                      <span className="text-gray-500 dark:text-gray-400 font-normal block">
                        @ppppatricia_
                      </span>
                    </div>
                  </div>
                </div>
                <p className="text-black dark:text-white block text-xl leading-snug mt-3">
                  Just tried abalone for the first time. Calmex pink label. 🩷 So
                  delicious
                </p>
              </div>

              <div className="bg-white dark:bg-gray-800 border-gray-200 dark:border-gray-800 p-4 rounded-xl border mb-6 break-inside-avoid">
                <div className="flex justify-between">
                  <div className="flex items-center">
                    <img
                      className="h-11 w-11 rounded-full"
                      src="pb-avatar-5432786468.png"
                    />
                    <div className="ml-1.5 text-sm leading-tight">
                      <span className="text-black dark:text-white font-bold block">
                        Oliver Green
                      </span>
                      <span className="text-gray-500 dark:text-gray-400 font-normal block">
                        @oliver__green
                      </span>
                    </div>
                  </div>
                </div>
                <p className="text-black dark:text-white block text-xl leading-snug mt-3">
                  I'm not even a big seafood guy, but I had some abalone and it
                  was surprisingly good... I might be a convert
                </p>
              </div>

              <div className="bg-white dark:bg-gray-800 border-gray-200 dark:border-gray-800 p-4 rounded-xl border mb-6 break-inside-avoid">
                <div className="flex justify-between">
                  <div className="flex items-center">
                    <img
                      className="h-11 w-11 rounded-full"
                      src="pb-avatar-5938728964.png"
                    />
                    <div className="ml-1.5 text-sm leading-tight">
                      <span className="text-black dark:text-white font-bold block">
                        Hannah Banana
                      </span>
                      <span className="text-gray-500 dark:text-gray-400 font-normal block">
                        @wherewhenn
                      </span>
                    </div>
                  </div>
                </div>
                <p className="text-black dark:text-white block text-xl leading-snug mt-3">
                  Who doesn't loves a good seafood paella? Abalone, mussels,
                  shrimp, sausage -- perfection!!
                </p>
              </div>

              <div className="bg-white dark:bg-gray-800 border-gray-200 dark:border-gray-800 p-4 rounded-xl border mb-6 break-inside-avoid">
                <div className="flex justify-between">
                  <div className="flex items-center">
                    <img
                      className="h-11 w-11 rounded-full"
                      src="pb-avatar-913472352342.png"
                    />
                    <div className="ml-1.5 text-sm leading-tight">
                      <span className="text-black dark:text-white font-bold block">
                        Michael Stratton
                      </span>
                      <span className="text-gray-500 dark:text-gray-400 font-normal block">
                        @xmstratsx
                      </span>
                    </div>
                  </div>
                </div>
                <p className="text-black dark:text-white block text-xl leading-snug mt-3">
                  🤔 saw this recipe for an abalone BURGER on the Calmex website
                  and ngl, it was better than I expected
                </p>
              </div>

              <div className="bg-white dark:bg-gray-800 border-gray-200 dark:border-gray-800 p-4 rounded-xl border mb-6 break-inside-avoid">
                <div className="flex justify-between">
                  <div className="flex items-center">
                    <img
                      className="h-11 w-11 rounded-full"
                      src="b_wwvBBJ_400x400.jpg"
                    />
                    <div className="ml-1.5 text-sm leading-tight">
                      <span className="text-black dark:text-white font-bold block ">
                        Sichuan Eats 吃么
                      </span>
                      <span className="text-gray-500 dark:text-gray-400 font-normal block">
                        @YumHe1558
                      </span>
                    </div>
                  </div>
                </div>
                <p className="text-black dark:text-white block text-xl leading-snug mt-3">
                  Chili fried noodles with abalone have a super flavor. If you
                  like spicy food, come try!
                  鲍鱼和辣椒炒香菇面超级有四川风味，喜欢吃辣的朋友快来尝尝吧。#川菜
                  #四川 #CALMEX
                </p>
                <img
                  className="mt-2 rounded-2xl border border-gray-100 dark:border-gray-700"
                  src="/pb-291715723.png"
                />
              </div>
            </div>
          </div>
        </div> */}

      </div>

      <Footer hideDivider={true} />
    </div>
  );
}

export default HomePage;
