import React from "react";
import { Helmet } from "react-helmet-async";
import Footer from "./Footer";

const Sustainability = () => {
  return (
    <div className="pageWrapper">
      <Helmet>
        <title>Sustainability | Calmex</title>
      </Helmet>
      <section
        style={{ zIndex: 9999, width: "100%" }}
        className="mt-48 absolute bg-black"
      >
        <div className="bg-black text-white background-wheel-sm md:pb-24">
          <div className="container mx-auto px-4 py-12">
            <div className="w-full">
              {/* overlap-b.png */}
              <div className="text-center">
              <img src="/overlap-b.png" className="mx-auto"/>
              </div>
              <h1 className="mb-12 text-5xl/[58px] font-inter lg:text-6xl/[70px] font-light tracking-tight text-center">
                Our Sustainability Process
              </h1>
              <div className="text-left font-inter">
                <h2 className="text-2xl font-bold mb-8"></h2>
                <p className="mb-8">
                  In our commitment to sustainable harvesting and environmental stewardship, we practice strict collection processes and protocols across our operations - from the shores of Western Australia to the coasts of Mexico. In addition to following these Calmex Standards, we adhere to the regulations laid out by government entities, for example collecting abalone only from formally qualified commercial fishing zones.
                </p>
                <div className="mb-8">
                  <h3 className="text-xl font-bold">These regulating entities are:</h3>
                  <div>
                    <div className="flag mexico-flag mr-2"></div>
                    <div className="mt-4">
                      <p className="font-bold text-xl">MEXICO:</p>
                      <p>FDA, USDC, NOAA, F&W.</p>
                    </div>
                  </div>
                  <div className="mt-4">
                    <div className="flag australia-flag mr-2"></div>
                    <div>
                      <p className="font-bold text-xl">AUSTRALIA</p>
                      <p>FDA, USDC, NOAA, AQIS, F&W.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex items-center mt-8 mb-12 gap-8">
              <div className="entity-logo mr-4">
                <img src="/usdc-logo.png" alt="USDC" />
              </div>
              <div className="entity-logo mr-4">
                <img src="/fda-logo.png" alt="FDA" />
              </div>
              <div className="entity-logo">
                <img src="noaa-logo.png" alt="NOAA" />
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </section>
    </div>
  );
};

export default Sustainability;
