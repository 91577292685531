import React, { useState, useEffect, useRef } from "react";
import { Helmet } from 'react-helmet-async';
import ScrollToTopLink from "./components/ScrollToTopLink";
import MenuItem from "./MenuItem";
import Footer from "./Footer";
import { useMediaQuery } from 'react-responsive';




function TopShellPage() {
  
  // Abbreviate the text for Australian Abalone on mobile
  const [ausText, setAusText] = useState('AUSTRALIAN ABALONE');
  const isMobile = useMediaQuery({ maxWidth: 767 });
  
  useEffect(() => {
    if (isMobile) {
      setAusText('AUS. ABALONE');
    } else {
      setAusText('AUSTRALIAN ABALONE');
    }
  }, [isMobile]);
  
  // State to manage the visibility of the splash container
  const [showSplash, setShowSplash] = useState(true);
  const contentContainerRef = useRef(null);

  useEffect(() => {
    // Set a timeout to hide the splash after 7 seconds
    const timer = setTimeout(() => {
      setShowSplash(false);
    }, 4000);

    // Cleanup the timer if the component unmounts before the timeout is reached
    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="pageWrapper">
      <Helmet>
      <title>Calmex Top Shell | Wild Caught Premium Seafood</title>
      </Helmet>
      {/* Apply dynamic styling based on showSplash state */}
      {/* When showSplash is true, fade in the text "MEXICAN ABALONE" */}
      <div className={`${!showSplash ? "" : "hidden"} videoOverlayContainer`}>
        <div className="headerAdjusted fadeInText">
          <h1 className="titleText">Abalone</h1>
          <h2 className="titleText subTitle">Calmex Pink Label</h2>
        </div>
        <div className="downwardArrow fadeInText bouncing">
          <p
            onClick={() =>
              contentContainerRef.current?.scrollIntoView({
                behavior: "smooth",
              })
            }
          >
            ↓
          </p>
        </div>
      </div>
      <div className={`splashContainer zoom-fade-effect`}>
        <div className={`splash ${showSplash ? "" : "hidden"}`}>
          <h1 className="jumboHeaderText">The Essence of Oceanic Luxury</h1>
          <div
            style={{
              marginTop: "88px",
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                width: "2px",
                height: "100px",
                backgroundColor: "#FFF",
                display: "inline-block",
                margin: "0 16px",
              }}
            ></div>

            <MenuItem
              text={"MEXICAN ABALONE"}
              imgSrc="/abalone-1.png"
              style={{ display: "inline-block" }}
            />

            <div
              style={{
                width: "2px",
                height: "100px",
                backgroundColor: "#FFF",
                display: "inline-block",
                margin: "0 16px",
              }}
            ></div>

            <MenuItem
              text={"AUSTRALIAN ABALONE"}
              imgSrc="/abalone-2.png"
              style={{ display: "inline-block" }}
            />

            <div
              style={{
                width: "2px",
                height: "100px",
                backgroundColor: "#FFF",
                display: "inline-block",
                margin: "0 16px",
              }}
            ></div>
            <MenuItem
              text={"TOP SHELL"}
              imgSrc="/abalone-3.png"
              style={{ display: "inline-block" }}
            />

            <div
              style={{
                width: "2px",
                height: "100px",
                backgroundColor: "#FFF",
                display: "inline-block",
                margin: "0 16px",
              }}
            ></div>
          </div>
        </div>
      </div>
      <div className="fadeDownToBlack"></div>
      <section
        style={{ zIndex: 9999, width: "100%" }}
        className="absolute bg-black"
      >
        {/* background: linear-gradient(0deg, #000000 0%, rgba(0, 0, 0, 0.86) 30%, rgba(0, 0, 0, 0) 100%); */}
        {/* Element to fade to black */}

        <div
          className="bg-black text-white"
          ref={contentContainerRef}
        >
          <div className="md:container mx-auto px-4 py-12 flex flex-wrap items-center justify-between">
            <div className="w-full mb-24 lg:mb-0">
              {/* Image of abalone-1.png */}
              <img
                src="/abalone-3.png"
                alt="Calmex Premium Wild Caught Top Shell"
                className="w-16 mb-3"
              />
              <h2 className="text-2xl font-bold text-orange-100 tracking-wider">
                PREMIUM TOP SHELL
              </h2>
              <h1 className="mt-4 text-5xl/[58px] font-inter lg:text-6xl/[70px] font-light tracking-tight">
                {/* Copy for top shell */}
                Indulge in the Delicacy of Top Shell
              </h1>
              <p className="mt-6 font-inter">
                {/* copy for TOP SHELL. ONLY */}
                  Savor the unique and delightful flavor of Calmex Top Shell. Being a cherished delicacy prized by chefs and food enthusiasts alike, it offers a distinct texture and a taste that's both subtle and rich. Top Shell is a versatile ingredient in endless dishes, with health benefits & luxury flavor that makes it the perfect choice to add a touch of elegance and sophistication to your menu. Experience the true essence of gourmet seafood with Calmex Top Shell.
              </p>
              {/* dividing line */}
              <div className="w-full border-b opacity-50 mt-12"></div>
              <p className="mt-12 text-2xl font-bold tracking-wider">
                {/* Explore our other products */}
                Explore similar products:
              </p>
              <div className="mt-16 inline-menu-items">
        {/* White vertical dividing line */}
        <div style={{ width: '2px', height: '100px', backgroundColor: '#FFF', display: 'inline-block', margin: '0 16px 0 0' }} className="ml-0"></div>
        <ScrollToTopLink to="/products/abalone" style={{ color: '#FFF', textDecoration: 'underline' }}>
      <MenuItem text={'MEXICAN ABALONE'} imgSrc='/abalone-1.png' style={{ display: 'inline-block' }} />
      </ScrollToTopLink>


      <div style={{ width: '2px', height: '100px', backgroundColor: '#FFF', display: 'inline-block', margin: '0 16px' }}></div>

      <ScrollToTopLink to="/products/australian-pink-label" style={{ color: '#FFF', textDecoration: 'underline' }}>
      <MenuItem text={ausText} imgSrc='/abalone-2.png' style={{ display: 'inline-block' }} />
      </ScrollToTopLink>

      <div style={{ width: '2px', height: '100px', backgroundColor: '#FFF', display: 'inline-block', margin: '0 16px' }}></div>

              </div>
            </div>
          </div>

          <div className="container mx-auto text-white mt-24">
          <div className="flex-col md:flex-row flex gap-24">
            <div className="font-inter text-4xl w-2/3 md:w-2/5">
              Discover Calmex Top Shell
            {/* top-shell-shell.png */}
            <img src="/top-shell-shell.png" alt="Top Shell" className="md:w-1/5 mt-6"/>
            </div>
            
            <div className="font-inter md:w-3/5 w-full">
            Let Calmex Top Shell inspire your exploration into luxury seafood, transforming ordinary meals into extraordinary experiences. Embrace the art of fine dining, and savor the flavors that define excellence. Your kitchen is the stage, and Calmex Top Shell is your star ingredient, ready to elevate your culinary adventures to new heights.
            </div>
          </div>
        </div>

        </div>



        <Footer />
      </section>
    </div>
  );
}

export default TopShellPage;
