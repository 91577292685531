import React from "react";
import Footer from "../Footer";

const AbaloneCreamStew = () => {
  return (
    <div className="pageWrapper">
      <section
        style={{ zIndex: 9999, width: "100%" }}
        className="mt-48 absolute bg-black"
      >
        <div className="bg-black text-white background-wheel">
          <div className="container mx-auto px-4 py-12 flex flex-wrap items-center justify-between">
            <div className="w-full">
              {/* Upper header pink text: RECIPE */}
              <h2 className="text-2xl font-bold text-pink-300 tracking-wider text-center">
                RECIPE
              </h2>
              <h1 className="mb-12 text-5xl/[58px] font-inter lg:text-6xl/[70px] font-light tracking-tight text-center">
                Abalone Cream Stew
              </h1>
            </div>
            <article
              id="post-499"
              className="post-499 post type-post status-publish format-standard hentry category-uncategorized font-inter"
            >
              <div className="post-thumbnail"></div>
              <div className="entry-content">
                <figure className="wp-block-image size-large">
                  <img
                    decoding="async"
                    loading="lazy"
                    width="819"
                    height="1024"
                    src="/Untitled-314-819x1024.jpg"
                    alt=""
                    className="wp-image-500"
                    srcSet="/Untitled-314-819x1024.jpg 819w, /Untitled-314-240x300.jpg 240w, /Untitled-314-768x960.jpg 768w, /Untitled-314.jpg 1080w"
                    sizes="(max-width: 819px) 100vw, 819px"
                  />
                </figure>
                <p>
                  As soon as the weather gets even just slightly cold we all tend to turn to our favorite warming comfort food. No more cold, refreshing dishes but instead a dish that will warm our hearts and our stomachs is preferred. This week our dish is a filling wild abalone cream stew that is easy and a perfect dish to share with others. Much like curry, you only need the pre-made roux that can be found at any Asian grocery store to make this deliciously savory cream mixture. We even used a bread bowl to make it a more exciting presentation. Feel free to add any seasonal squashes or pumpkins to make this an Autumn favorite!
                </p>
                <figure className="wp-block-image size-large">
                  <img
                    decoding="async"
                    loading="lazy"
                    width="819"
                    height="1024"
                    src="/Untitled-316-819x1024.jpg"
                    alt=""
                    className="wp-image-501"
                    srcSet="/Untitled-316-819x1024.jpg 819w, /Untitled-316-240x300.jpg 240w, /Untitled-316-768x960.jpg 768w, /Untitled-316.jpg 1080w"
                    sizes="(max-width: 819px) 100vw, 819px"
                  />
                </figure>
                <p>Ingredients:</p>
                <p>1 Can Calmex Wild Abalone</p>
                <p>1-2 Potatoes</p>
                <p>2-4 Carrots</p>
                <p>1 Whole onion</p>
                <p>1 Package of cream stew roux</p>
                <p>1 Whole sourdough bread with the middle scooped out</p>
                <p>1 Tbsp of butter</p>
                <p>Pinch of cracked black pepper</p>
                <figure className="wp-block-image size-large">
                  <img
                    decoding="async"
                    loading="lazy"
                    width="819"
                    height="1024"
                    src="/Untitled-315-819x1024.jpg"
                    alt=""
                    className="wp-image-502"
                    srcSet="/Untitled-315-819x1024.jpg 819w, /Untitled-315-240x300.jpg 240w, /Untitled-315-768x960.jpg 768w, /Untitled-315.jpg 1080w"
                    sizes="(max-width: 819px) 100vw, 819px"
                  />
                </figure>
                <p>Directions:</p>
                <p>Prep and chop up all of your vegetables in bite-sized pieces.</p>
                <p>Heat a large pot over medium heat with butter. Add your carrots and potatoes first with salt and pepper to taste. Cover the pot with a lid for approximately 5 minutes stirring occasionally.</p>
                <p>In the meantime, slice up your wild abalone into slices and set aside.</p>
                <p>Carefully hollow out your sourdough bread bowl. You can save the inside for another recipe perhaps to make croutons. Toast the bowl for a more crunchy texture.</p>
                <p>Once carrots and potatoes are soft, throw in your onions and saute for another 3-5 minutes.</p>
                <p>Once onions are translucent, pour in the pot with enough water to cover all the ingredients inside.</p>
                <p>Leave stove on medium-high heat until water boils. Then, add cream roux into the pot and stir until thickened. Add more roux as needed to get the thick consistency.</p>
                <p>Turn off the stove and let your cream stew cool down before adding the slices of wild abalone. Stir for another few minutes and then gently add into the bread bowl.</p>
                <p>Take a scoop full and gently pour into the hollowed bread bowl and serve to your guests! Be sure to rip off some of the bread to dip it into the wild abalone cream stew and enjoy your warm hearty meal to share with everyone!</p>
              </div>
              <footer className="entry-footer"></footer>
            </article>
          </div>
        </div>
        <Footer />
      </section>
    </div>
  );
};

export default AbaloneCreamStew;
