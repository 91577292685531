import React from "react";
import Footer from "../Footer";

const WildAbaloneGravy = () => {
  return (
    <div className="pageWrapper">
      <section
        style={{ zIndex: 9999, width: "100%" }}
        className="mt-48 absolute bg-black"
      >
        <div className="bg-black text-white background-wheel">
          <div className="container mx-auto px-4 py-12 flex flex-wrap items-center justify-between">
            <div className="w-full">
              {/* Upper header pink text: RECIPE */}
              <h2 className="text-2xl font-bold text-pink-300 tracking-wider text-center">
                RECIPE
              </h2>
              <h1 className="mb-12 text-5xl/[58px] font-inter lg:text-6xl/[70px] font-light tracking-tight text-center">
                Wild Abalone Gravy
              </h1>
            </div>
            <article
              id="post-514"
              className="post-514 post type-post status-publish format-standard has-post-thumbnail hentry category-uncategorized font-inter"
            >
              <div className="post-thumbnail">
                <img
                  width="1080"
                  height="1350"
                  src="/Untitled-333.png"
                  className="attachment-post-thumbnail size-post-thumbnail wp-post-image"
                  alt=""
                  decoding="async"
                  loading="lazy"
                  srcSet="/Untitled-333.png 1080w, /Untitled-333-240x300.png 240w, /Untitled-333-819x1024.png 819w, /Untitled-333-768x960.png 768w"
                  sizes="(max-width: 1080px) 100vw, 1080px"
                />
              </div>
              <div className="entry-content">
                <figure className="wp-block-image size-large">
                  <img
                    decoding="async"
                    loading="lazy"
                    width="819"
                    height="1024"
                    src="/Untitled-331-819x1024.png"
                    alt=""
                    className="wp-image-516"
                    srcSet="/Untitled-331-819x1024.png 819w, /Untitled-331-240x300.png 240w, /Untitled-331-768x960.png 768w, /Untitled-331.png 1080w"
                    sizes="(max-width: 819px) 100vw, 819px"
                  />
                </figure>
                <p>
                  Here in the States, Thanksgiving is an exciting family feast where food brings together family and friends all around the dinner table! Although turkey is usually the dinner main course, we believe the sides are the supporting role in making the feast interesting and delicious!
                </p>
                <p>
                  If you happen to serve our delicious wild abalone as an appetizer, be sure to keep the abalone brine from the can! This makes a savory base for your gravy without the juicy flavors going to waste. Once your make your abalone gravy be sure to pour that over all your sides. It goes especially well with mashed potatoes, stuffing, and even crab cakes!
                </p>
                <figure className="wp-block-image size-large">
                  <img
                    decoding="async"
                    loading="lazy"
                    width="819"
                    height="1024"
                    src="/Untitled-332-819x1024.png"
                    alt=""
                    className="wp-image-515"
                    srcSet="/Untitled-332-819x1024.png 819w, /Untitled-332-240x300.png 240w, /Untitled-332-768x960.png 768w, /Untitled-332.png 1080w"
                    sizes="(max-width: 819px) 100vw, 819px"
                  />
                </figure>
                <p>Ingredients:</p>
                <p>1 Can Calmex Wild Abalone (brine)</p>
                <p>1 Tablespoon Flour</p>
                <p>1 Stick of Butter</p>
                <p>Salt + Pepper to taste</p>
                <p>1 Tablespoon Heavy Cream</p>
                <p>Directions:</p>
                <p>Heat a small simmer pan with butter until melted</p>
                <p>Add some salt and pepper to taste</p>
                <p>Sprinkle 1 tablespoon of flour and mix until thick</p>
                <p>Add your Calmex Wild Abalone brine slowly and just a few tablespoons at a time while still mixing constantly</p>
                <p>Be sure to add your tablespoon of heavy cream for a creamier texture and keep mixing!</p>
                <p>The key is to constantly stir so the gravy does not clump up and get chunky.</p>
                <p>Serve in sauce dish for your guests and watch it be used for every side dish!</p>
                <p>Enjoy and Happy Thanksgiving from CALMEX!</p>
                <figure className="wp-block-image size-large">
                  <img
                    decoding="async"
                    src="/Untitled-332-819x1024.png"
                    alt=""
                  />
                </figure>
              </div>
              <footer className="entry-footer"></footer>
            </article>
          </div>
        </div>
        <Footer />
      </section>
    </div>
  );
};

export default WildAbaloneGravy;
