import React, { useState, useEffect, useRef } from "react";
import { Helmet } from 'react-helmet-async';
import ScrollToTopLink from "./components/ScrollToTopLink";
import MenuItem from "./MenuItem";
import Footer from "./Footer";
import { useMediaQuery } from 'react-responsive';

function AbalonePage() {
  // State to manage the visibility of the splash container
  const [showSplash, setShowSplash] = useState(true);
  const contentContainerRef = useRef(null);

  useEffect(() => {
    // Set a timeout to hide the splash after 7 seconds
    const timer = setTimeout(() => {
      setShowSplash(false);
    }, 4000);

    // Cleanup the timer if the component unmounts before the timeout is reached
    return () => clearTimeout(timer);
  }, []);

    // Abbreviate the text for Australian Abalone on mobile
    const [ausText, setAusText] = useState('AUSTRALIAN ABALONE');
    const isMobile = useMediaQuery({ maxWidth: 767 });
    
    useEffect(() => {
      if (isMobile) {
        setAusText('AUS. ABALONE');
      } else {
        setAusText('AUSTRALIAN ABALONE');
      }
    }, [isMobile]);

  return (
    <div className="pageWrapper">
      <Helmet>
      <title>Calmex Pink Label | Wild Caught Mexican Abalone</title>
      </Helmet>
      {/* Apply dynamic styling based on showSplash state */}
      {/* When showSplash is true, fade in the text "MEXICAN ABALONE" */}
      <div className={`${!showSplash ? "" : "hidden"} videoOverlayContainer`}>
        <div className="headerAdjusted fadeInText">
          <h1 className="titleText">Abalone</h1>
          <h2 className="titleText subTitle">Calmex Pink Label</h2>
        </div>
        <div className="downwardArrow fadeInText bouncing">
          <p
            onClick={() =>
              contentContainerRef.current?.scrollIntoView({
                behavior: "smooth",
              })
            }
          >
            ↓
          </p>
        </div>
      </div>
      <div className={`splashContainer zoom-fade-effect`}>
        <div className={`splash ${showSplash ? "" : "hidden"}`}>
          <h1 className="jumboHeaderText">The Essence of Oceanic Luxury</h1>
          <div
            style={{
              marginTop: "88px",
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                width: "2px",
                height: "100px",
                backgroundColor: "#FFF",
                display: "inline-block",
                margin: "0 16px",
              }}
            ></div>

            <MenuItem
              text={"MEXICAN ABALONE"}
              imgSrc="/abalone-1.png"
              style={{ display: "inline-block" }}
            />

            <div
              style={{
                width: "2px",
                height: "100px",
                backgroundColor: "#FFF",
                display: "inline-block",
                margin: "0 16px",
              }}
            ></div>

            <MenuItem
              text={"AUSTRALIAN ABALONE"}
              imgSrc="/abalone-2.png"
              style={{ display: "inline-block" }}
            />

            <div
              style={{
                width: "2px",
                height: "100px",
                backgroundColor: "#FFF",
                display: "inline-block",
                margin: "0 16px",
              }}
            ></div>
            <MenuItem
              text={"TOP SHELL"}
              imgSrc="/abalone-3.png"
              style={{ display: "inline-block" }}
            />

            <div
              style={{
                width: "2px",
                height: "100px",
                backgroundColor: "#FFF",
                display: "inline-block",
                margin: "0 16px",
              }}
            ></div>
          </div>
        </div>
      </div>
      <div className="fadeDownToBlack"></div>
      <section
        style={{ zIndex: 9999, width: "100%" }}
        className="absolute bg-black"
      >
        {/* background: linear-gradient(0deg, #000000 0%, rgba(0, 0, 0, 0.86) 30%, rgba(0, 0, 0, 0) 100%); */}
        {/* Element to fade to black */}

        <div
          className="bg-black text-white background-wheel"
          ref={contentContainerRef}
        >
          <div className="md:container mx-auto px-4 py-12 flex flex-wrap items-center justify-between">
            <div className="w-full lg:w-1/2 mb-24 lg:mb-0">
              {/* Image of abalone-1.png */}
              <img
                src="/abalone-1.png"
                alt="Calmex Premium Wild Mexican Abalone"
                className="w-16 mb-3"
              />
              <h2 className="text-2xl font-bold text-pink-300 tracking-wider">
                PREMIUM WILD MEXICAN ABALONE
              </h2>
              <h1 className="mt-4 text-5xl/[58px] font-inter lg:text-6xl/[70px] font-light tracking-tight">
                A taste of the ocean's bounty
              </h1>
              <p className="mt-6 font-inter">
                For over a century, Calmex has been synonymous with the finest
                wild-caught abalone. Our premium pink label abalone, harvested
                from the pacific coasts of Mexico, is a testament to that
                legacy. We are proud to offer this unparalleled delicacy that is
                not only a culinary experience, but also a symbol of the ocean's
                bounty, delivered in its iconic pink can.
              </p>
              {/* dividing line */}
              <div className="w-full border-b opacity-50 mt-12"></div>
              <p className="mt-12 text-2xl font-bold tracking-wider">
                {/* Explore our other products */}
                Explore similar products:
              </p>
              <div className="mt-16 inline-menu-items">
        {/* White vertical dividing line */}
        <div style={{ width: '2px', height: '100px', backgroundColor: '#FFF', display: 'inline-block', margin: '0px 16px 0 0' }}></div>

      <ScrollToTopLink to="/products/australian-pink-label" style={{ color: '#FFF', textDecoration: 'underline' }}>
      <MenuItem text={ausText} imgSrc='/abalone-2.png' style={{ display: 'inline-block' }} />
      </ScrollToTopLink>

      <div style={{ width: '2px', height: '100px', backgroundColor: '#FFF', display: 'inline-block', margin: '0 16px' }}></div>

      <ScrollToTopLink to="/products/top-shell" style={{ color: '#FFF', textDecoration: 'underline' }}>
      <MenuItem text={'TOP SHELL'} imgSrc='/abalone-3.png' style={{ display: 'inline-block' }} />
      </ScrollToTopLink>

      <div style={{ width: '2px', height: '100px', backgroundColor: '#FFF', display: 'inline-block', margin: '0 16px' }}></div>

              </div>
            </div>
            <div className="w-full lg:w-1/2 flex justify-center">
              <img
                src="/premium-abalone.png"
                alt="Wild-caught abalone"
              />
            </div>
          </div>

          

          {/* <div className="container mx-auto px-4 py-12 flex flex-wrap items-center justify-between lg:space-x-8">
            <div className="w-full lg:w-3/6 flex justify-start mb-12 lg:mb-0">
              <img
                src="/premium-abalone-dish-luxury-table-setting.png"
                alt="Abalone seafood plates"
              />
            </div>
            <div className="w-full lg:w-2/5">

              <img
                src="/abalone-1.png"
                alt="Calmex Premium Wild Mexican Abalone"
                className="w-16 mb-3"
              />
              <h2 className="text-2xl font-bold text-pink-300 tracking-wider">
                PREMIUM WILD MEXICAN ABALONE
              </h2>
              <h1 className="mt-4 text-5xl/[58px] font-inter lg:text-6xl/[70px] font-light tracking-tight">
                A taste of the ocean's bounty
              </h1>
              <p className="mt-6 font-inter">
                For over a century, Calmex has been synonymous with the finest
                wild-caught abalone. Our premium pink label abalone, harvested
                from the pacific coasts of Mexico, is a testament to that
                legacy. We are proud to offer this unparalleled delicacy that is
                not only a culinary experience, but also a symbol of the ocean's
                bounty, delivered in its iconic pink can.
              </p>
              <p className="mt-12">
                <a
                  href="/"
                  className="text-xl py-4 px-8 border-pink-300 border text-pink-300 rounded hover:bg-pink-300 hover:text-black transition duration-300 tracking-[6px] w-full"
                >
                  Read More
                </a>
              </p>
            </div>
          </div> */}

          <div className="relative text-white container mx-auto lg:mt-24">
            <img
              src="/coastal-background.png"
              alt="Background"
              className="w-full h-auto hidden lg:block"
            />
            <div className="lg:absolute top-0 left-0 w-full h-full flex flex-col items-center justify-center lg:p-8">
              <h2 className="text-5xl mb-8 font-inter font-thin text-center lg:text-left">
                Why is{" "}
                <b className="calmex-gradient font-semibold">
                  wild-caught abalone
                </b>{" "}
                superior?
              </h2>
              <div className="grid grid-cols-1 lg:grid-cols-4 gap-16 lg:gap-4 text-center">
                <div className="md:mt-0 mt-18">
                  {/* Crown icon image */}
                  <img
                    src="/crown-icon.png"
                    alt="Crown icon"
                    className="w-12 h-auto mx-auto mb-8"
                  />
                  <h3 className="text-pink-200 text-3xl tracking-wider">
                    PRISTINE SELECTION
                  </h3>
                  <p className="text-sm mt-6 lg:mt-12 font-inter px-4">
                    Calmex abalone symbolizes the vigor of the untamed sea.
                    Unlike farm-raised variants, to thrive in the wild oceans,
                    our abalones naturally develop a robustness unmatched in
                    flavor and texture, ensuring the highest quality.
                  </p>
                </div>
                <div className="md:mt-0 mt-18">
                  {/* Health benefits icon image */}
                  <img
                    src="/health-benefits-icon.png"
                    alt="Health benefits icon"
                    className="w-9 h-auto mx-auto mb-8"
                  />
                  <h3 className="text-pink-200 text-3xl tracking-wider">
                    HEALTH BENEFITS
                  </h3>
                  <p className="text-sm mt-6 lg:mt-12 font-inter px-4">
                    Wild abalone offers a wealth of health benefits, rich in
                    protein, omega-3 fatty acids, and essential vitamins and
                    minerals like vitamin B12 and magnesium. These nutrients
                    help improve heart health, enhance brain function, and boost
                    the immune system.
                  </p>
                </div>
                <div className="md:mt-0 mt-18">
                  {/* Sustainability icon image */}
                  <img
                    src="/sustainability-icon.png"
                    alt="Sustainability icon"
                    className="w-12 h-auto mx-auto mb-8"
                  />
                  <h3 className="text-pink-200 text-3xl tracking-wider">
                    SUSTAINABLY SOURCED
                  </h3>
                  <p className="text-sm mt-6 lg:mt-12 font-inter px-4">
                    Our abalone is sustainably sourced to protect the oceanic
                    ecosystem. By carefully observing and respecting biomass
                    replacement rates, we commit to ensuring the continuation of
                    natural cycles and the prosperity of the marine biome.
                  </p>
                </div>
                <div className="md:mt-0 mt-18">
                  {/* Wave icon image */}
                  <img
                    src="/wave-icon.png"
                    alt="Wave icon"
                    className="w-12 h-auto mx-auto mb-8"
                  />
                  <h3 className="text-pink-200 text-3xl tracking-wider">
                    PUREST WATERS
                  </h3>
                  <p className="text-sm mt-6 lg:mt-12 font-inter px-4">
                    Calmex abalone is sourced from the purest marine environments, enhancing quality and flavor.
                    Our lasting commitment to excellence has ensured a superior
                    product free from pollutants and contaminants.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="container mx-auto mt-14">
            <div className="flex mx-auto text-white">
              <div className="lg:flex">
                <div className="w-full lg:w-1/3 lg:p-8 mb-24 lg:mb-0 self-center">
                  <h2 className="text-2xl font-bold text-pink-300 tracking-wider">
                    Luxury Delicacy of the Sea
                  </h2>
                  <h1 className="mt-4 font-inter text-4xl/[48px] lg:text-5xl/[55px] font-light tracking-tight mb-4">
                    Unmatched quality
                  </h1>
                  <p className="mb-6 mt-6 font-inter">
                    Calmex abalone is the first choice of top chefs,
                    discerning gourmands, and families around the world. With
                    its unparalleled flavor, nutrition, and quality, Calmex wild
                    Mexican abalone delivers a culinary experience like no other
                    - a true taste of the ocean's bounty.
                  </p>

                  <p className="mb-6 mt-12">
                    <a
                      href="/recipes"
                      className="text-xl py-4 px-8 border-pink-300 border text-pink-300 rounded hover:bg-pink-300 hover:text-black transition duration-300 tracking-[6px]"
                    >
                      See Recipes
                    </a>
                  </p>
                </div>

                <div className="w-full lg:w-2/3 lg:grid grid-cols-2 gap-4 px-4 md:min-h-[600px]">

                  <div className="relative self-end mb-8 lg:mb-0">
                    <img
                      src="/grid-img-2.png"
                      className="w-full lg:w-auto"
                      alt="A close-up of a fried abalone dish"
                    />
                    <div className=" bottom-0 left-0 p-2 w-full text-left text-pink-300 text-2xl">
                      Unparalleled Flavor, Nutrition, & Quality
                    </div>
                  </div>
                  <div className="relative self-center mb-8 lg:mb-0">
                    <img
                      src="/grid-img-1.png"
                      className="w-full lg:w-auto"
                      alt="A collage of dishes"
                    />
                    <div className="bottom-0 left-0 p-2 w-full text-left text-pink-300 text-2xl">
                      Unlimited culinary possibilities
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div className="w-full lg:grid lg:grid-cols-3 gap-4 px-4 mt-12 md:min-h-[600px]">
                <div className="relative self-end mb-8 lg:mb-0">
                  <img
                    src="/grid-img-3.png"
                    className="w-full lg:w-auto"
                    alt="An aerial view of a pristine shoreline"
                  />
                  <div className=" bottom-0 left-0 p-2 w-full text-left text-pink-300 text-2xl">
                    Wild-caught in pristine Mexican waters
                  </div>
                </div>
                <div className="relative self-center mb-8 lg:mb-0">
                  <img
                    src="/grid-img-4.png"
                    className="w-full lg:w-auto"
                    alt="An old photograph of a diver in a full-body suit and large diving helmet sitting on the stern of a fishing boat"
                  />
                  <div className=" bottom-0 left-0 p-2 w-full text-left text-pink-300 text-2xl">
                    Commitment to sustainable harvesting
                  </div>
                </div>
                <div className="relative mb-8 lg:mb-0">
                  <img
                    src="/grid-img-5.png"
                    className="w-full lg:w-auto"
                    alt="A diver swimming 20 feet below the surface in an underwater ravine"
                  />
                  <div className=" bottom-0 left-0 p-2 w-full text-left text-pink-300 text-2xl">
                    Ocean's culinary treasure
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="splash-element flex justify-center mt-12 mb-8 px-4">
          <img
            src="/footer-splash-element.png"
            alt="Footer Splash Element"
            className="max-w-[1280px] w-full"
          />
        </div>

        <div className="container mx-auto text-white">
          <div className="grid md:grid-cols-3 grid-cols-1 gap-12">
            <div className="font-inter text-4xl">
              Experience Calmex Wild Mexican Abalone
            </div>
            <div className="font-inter">
              {/* For more than 30 years, we’ve supplied customers with the finest,
              freshly caught fish around. Our entire selection is sustainably
              sourced and is carefully procured throughout the year. Click to
              discover quality wet and farmed fish as well as deli, live and
              frozen products. */}
              {/* Get creative here: */}
              Whether you're a professional chef or a home cook, Calmex Pink
              Label wild Mexican abalone is the perfect addition. Our premium
              abalone is a versatile delicacy that can be enjoyed in a variety
              of ways - from traditional recipes to modern fusion dishes.
              Discover the culinary possibilities of Calmex abalone.
            </div>
            <div className="flex justify-center">
              <p className="mt-6">
              <ScrollToTopLink
                to="/"
                className="text-xl py-4 px-8 border-pink-300 border text-pink-300 rounded hover:bg-pink-300 hover:text-black transition duration-300 tracking-[6px] block"
              >
                See Products
              </ScrollToTopLink>

              </p>
            </div>
          </div>
        </div>

        <Footer />
      </section>
    </div>
  );
}

export default AbalonePage;
