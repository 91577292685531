import React, { useState, useEffect, useRef } from "react";
import { Helmet } from 'react-helmet-async';
import ScrollToTopLink from "./components/ScrollToTopLink";
import MenuItem from "./MenuItem";
import Footer from "./Footer";

function AbalonePage() {
  // State to manage the visibility of the splash container
  const [showSplash, setShowSplash] = useState(true);
  const contentContainerRef = useRef(null);

  useEffect(() => {
    // Set a timeout to hide the splash after 7 seconds
    const timer = setTimeout(() => {
      setShowSplash(false);
    }, 4000);

    // Cleanup the timer if the component unmounts before the timeout is reached
    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="pageWrapper">
      <Helmet>
      <title>Calmex Australian Pink Label | Wild Caught Australian Abalone</title>
      </Helmet>
      {/* Apply dynamic styling based on showSplash state */}
      {/* When showSplash is true, fade in the text "MEXICAN ABALONE" */}
      <div className={`${!showSplash ? "" : "hidden"} videoOverlayContainer`}>
        <div className="headerAdjusted fadeInText">
          <h1 className="titleText">Abalone</h1>
          <h2 className="titleText subTitle">Calmex Pink Label</h2>
        </div>
        <div className="downwardArrow fadeInText bouncing">
          <p
            onClick={() =>
              contentContainerRef.current?.scrollIntoView({
                behavior: "smooth",
              })
            }
          >
            ↓
          </p>
        </div>
      </div>
      <div className={`splashContainer zoom-fade-effect`}>
        <div className={`splash ${showSplash ? "" : "hidden"}`}>
          <h1 className="jumboHeaderText">The Essence of Oceanic Luxury</h1>
          <div
            style={{
              marginTop: "88px",
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                width: "2px",
                height: "100px",
                backgroundColor: "#FFF",
                display: "inline-block",
                margin: "0 16px",
              }}
            ></div>

            <MenuItem
              text={"MEXICAN ABALONE"}
              imgSrc="/abalone-1.png"
              style={{ display: "inline-block" }}
            />

            <div
              style={{
                width: "2px",
                height: "100px",
                backgroundColor: "#FFF",
                display: "inline-block",
                margin: "0 16px",
              }}
            ></div>

            <MenuItem
              text={"AUSTRALIAN ABALONE"}
              imgSrc="/abalone-2.png"
              style={{ display: "inline-block" }}
            />

            <div
              style={{
                width: "2px",
                height: "100px",
                backgroundColor: "#FFF",
                display: "inline-block",
                margin: "0 16px",
              }}
            ></div>
            <MenuItem
              text={"TOP SHELL"}
              imgSrc="/abalone-3.png"
              style={{ display: "inline-block" }}
            />

            <div
              style={{
                width: "2px",
                height: "100px",
                backgroundColor: "#FFF",
                display: "inline-block",
                margin: "0 16px",
              }}
            ></div>
          </div>
        </div>
      </div>
      <div className="fadeDownToBlack"></div>
      <section
        style={{ zIndex: 9999, width: "100%" }}
        className="absolute bg-black"
      >
        {/* background: linear-gradient(0deg, #000000 0%, rgba(0, 0, 0, 0.86) 30%, rgba(0, 0, 0, 0) 100%); */}
        {/* Element to fade to black */}

        <div
          className="bg-black text-white"
          ref={contentContainerRef}
        >
          <div className="md:container mx-auto px-4 py-12 flex flex-wrap items-center justify-between">
            <div className="w-full lg:w-1/2 mb-24 lg:mb-0">
              {/* Image of abalone-1.png */}
              <img
                src="/abalone-2.png"
                alt="Calmex Premium Wild Caught Australian Abalone"
                className="w-16 mb-3"
              />
              <h2 className="text-2xl font-bold text-orange-300 tracking-wider">
                PREMIUM WILD CAUGHT AUSTRALIAN ABALONE
              </h2>
              <h1 className="mt-4 text-5xl/[58px] font-inter lg:text-6xl/[70px] font-light tracking-tight">
                Savor the essence of Australian waters
              </h1>
              <p className="mt-6 font-inter">
                Calmex sets the standard for wild-caught abalone. Our Premium Pink Label Australian Wild Abalone brings you the finest flavors from the oceans of Australia, with each piece a testament to our dedication to quality and sustainability, offering a gourmet experience that transports you straight to the pristine Australian coast.
              </p>
              {/* dividing line */}
              <div className="w-full border-b opacity-50 mt-12"></div>
              <p className="mt-12 text-2xl font-bold tracking-wider">
                {/* Explore our other products */}
                Explore similar products:
              </p>
              <div className="mt-16 inline-menu-items">
        {/* White vertical dividing line */}
        <div style={{ width: '2px', height: '100px', backgroundColor: '#FFF', display: 'inline-block', margin: '0px 16px 0 0' }}></div>

      <ScrollToTopLink to="/products/abalone" style={{ color: '#FFF', textDecoration: 'underline' }}>
      <MenuItem text={'MEXICAN ABALONE'} imgSrc='/abalone-1.png' style={{ display: 'inline-block' }} />
      </ScrollToTopLink>

      <div style={{ width: '2px', height: '100px', backgroundColor: '#FFF', display: 'inline-block', margin: '0 16px' }}></div>

      <ScrollToTopLink to="/products/top-shell" style={{ color: '#FFF', textDecoration: 'underline' }}>
      <MenuItem text={'TOP SHELL'} imgSrc='/abalone-3.png' style={{ display: 'inline-block' }} />
      </ScrollToTopLink>

      <div style={{ width: '2px', height: '100px', backgroundColor: '#FFF', display: 'inline-block', margin: '0 16px' }}></div>

              </div>
            </div>
            <div className="w-full lg:w-1/2 flex justify-center">
              <img
                src="/australian-fishing-boat.png"
                alt="Australian fishing boat"
              />
            </div>
          </div>

          <div className="container mx-auto mt-14">
            <div className="flex mx-auto text-white">
              <div className="lg:flex">
                <div className="w-full lg:w-1/3 lg:p-8 mb-24 lg:mb-0 self-center">
                  <h1 className="mt-4 font-inter text-4xl/[48px] lg:text-5xl/[55px] font-light tracking-tight mb-4">
                  The Pinnacle of Oceanic Luxury
                  </h1>
                  <p className="mb-6 mt-6 font-inter">
                  The finest superfood treasure of Australia's shores, Calmex wild Australian abalone offers a rare and sumptuous taste experience, transforming any dish into a culinary masterpiece. Cherished by chefs and food lovers, this delicacy turns everyday meals into extraordinary feasts. Let the waves of flavor wash over your palate.
                  </p>

                  <p className="mb-6 mt-12">
                    <a
                      href="/recipes"
                      className="text-xl py-4 px-8 border-orange-300 border text-orange-300 rounded hover:bg-orange-300 hover:text-black transition duration-300 tracking-[6px]"
                    >
                      See Recipes
                    </a>
                  </p>
                </div>

                <div className="w-full lg:w-2/3 lg:grid grid-cols-2 gap-4 px-4 md:min-h-[600px]">

                  <div className="relative self-center mb-8 lg:mb-0">
                    <img
                      src="/img-b1.png"
                      className="w-full lg:w-auto"
                      alt="A photo of a tabletop with a plate of sliced Australian abalone beside a salad and lemon garnish"
                    />
                    <div className=" bottom-0 left-0 p-2 w-full text-left text-orange-300 text-2xl">
                      Healthy & Delicious Luxury Superfood
                    </div>
                  </div>
                  <div className="relative self-end mb-8 lg:mb-0">
                    <img
                      src="/img-b2.png"
                      className="w-full lg:w-auto"
                      alt="An aerial view of an Australian shore, high cliffs flanking the ocean"
                    />
                    <div className="bottom-0 left-0 p-2 w-full text-left text-orange-300 text-2xl">
                      Pure & Sustainable
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container mx-auto text-white mt-24">
          <div className="flex flex-col md:flex-row gap-24">
            <div className="font-inter text-4xl md:w-1/3">
              Experience Calmex Wild Australian Abalone
            </div>
            <div className="font-inter md:w-2/3 w-full">
            Elevate your culinary creations with the exquisite taste of Calmex Wild Australian Abalone. Renowned for its tender texture and rich flavor, this premium abalone is harvested from the pristine waters of Australia. Whether you're crafting gourmet dishes in a professional kitchen or experimenting with new recipes at home, Calmex Wild Australian Abalone promises an unparalleled dining experience. Explore a world of culinary inspiration and let the unique flavors of this delicacy transform your meals.
            </div>
          </div>
        </div>

        <Footer />
      </section>
    </div>
  );
}

export default AbalonePage;
