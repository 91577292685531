import React from "react";
import Footer from "../Footer";

const WildAbaloneCaesarSalad = () => {
  return (
    <div className="pageWrapper">
      <section
        style={{ zIndex: 9999, width: "100%" }}
        className="mt-48 absolute bg-black"
      >
        <div className="bg-black text-white background-wheel">
          <div className="container mx-auto px-4 py-12 flex flex-wrap items-center justify-between">
            <div className="w-full">
              {/* Upper header pink text: RECIPE */}
              <h2 className="text-2xl font-bold text-pink-300 tracking-wider text-center">
                RECIPE
              </h2>
              <h1 className="mb-12 text-5xl/[58px] font-inter lg:text-6xl/[70px] font-light tracking-tight text-center">
                Wild Abalone Caesar Salad
              </h1>
            </div>
            <article
              id="post-459"
              className="post-459 post type-post status-publish format-standard hentry category-uncategorized font-inter"
            >
              <div className="post-thumbnail"></div>
              <div className="entry-content">
                <figure className="wp-block-image size-large">
                  <img
                    decoding="async"
                    loading="lazy"
                    width="1024"
                    height="819"
                    src="/Untitled-154-1024x819.jpg"
                    alt=""
                    className="wp-image-463"
                    srcSet="/Untitled-154-1024x819.jpg 1024w, /Untitled-154-300x240.jpg 300w, /Untitled-154-768x614.jpg 768w, /Untitled-154.jpg 1350w"
                    sizes="(max-width: 1024px) 100vw, 1024px"
                  />
                </figure>
                <p>
                  The classic caesar salad is a perfect cold dish for summer. With crisp romaine lettuce hearts, crunchy croutons, and a delicious dressing, there is so much to love about caesar salads!
                </p>
                <p>
                  Caesar salad is often served with grilled chicken, shrimp, or even salmon! We thought, “why not try the classic salad with abalone”? The creamy homemade caesar salad dressing will go perfectly with the protein-packed abalone for any seafood lover. Top off the salad with croutons and grated parmesan cheese and your guests will be impressed!
                </p>
                <figure className="wp-block-image size-large">
                  <img
                    decoding="async"
                    loading="lazy"
                    width="1024"
                    height="819"
                    src="/Untitled-152-1024x819.jpg"
                    alt=""
                    className="wp-image-460"
                    srcSet="/Untitled-152-1024x819.jpg 1024w, /Untitled-152-300x240.jpg 300w, /Untitled-152-768x614.jpg 768w, /Untitled-152.jpg 1350w"
                    sizes="(max-width: 1024px) 100vw, 1024px"
                  />
                </figure>
                <p><strong>Ingredients</strong>:</p>
                <p>1 Can Calmex Wild Abalone</p>
                <p>Chopped romaine lettuce or any salad greens</p>
                <p>1 Parmesan cheese block (grated or shredded)</p>
                <p>1 Cup of croutons</p>
                <p>Dressing Ingredients:</p>
                <p>1 Can anchovy in oil</p>
                <p>1 Garlic clove</p>
                <p>2 Egg yolks</p>
                <p>2 Tablespoons lemon juice</p>
                <p>¾ Teaspoon dijon mustard</p>
                <p>1 Teaspoon fish sauce</p>
                <p>3 Tablespoons olive oil</p>
                <p>2 Tablespoons grated parmesan cheese</p>
                <p>Black Pepper</p>
                <figure className="wp-block-image size-large">
                  <img
                    decoding="async"
                    loading="lazy"
                    width="1024"
                    height="819"
                    src="/Untitled-153-1-1024x819.jpg"
                    alt=""
                    className="wp-image-462"
                    srcSet="/Untitled-153-1-1024x819.jpg 1024w, /Untitled-153-1-300x240.jpg 300w, /Untitled-153-1-768x614.jpg 768w, /Untitled-153-1.jpg 1350w"
                    sizes="(max-width: 1024px) 100vw, 1024px"
                  />
                </figure>
                <p><strong>Directions</strong>:</p>
                <p>
                  Let’s make the dressing first! Finely chop the anchovies (about 4-5) along with the garlic and a pinch of salt until a paste is formed.
                </p>
                <p>
                  Whisk in 2 egg yolks, 2 tablespoons of lemon juice, 1 teaspoon of fish sauce, and ¾ mustard.
                </p>
                <p>
                  Slowly whisk in the olive oil little by little until dressing is thick and glossy.
                </p>
                <p>
                  Sprinkle 2 tablespoons of the grated parmesan cheese and season with salt and black pepper and taste. Add more ingredients to get your desired taste of caesar salad dressing.
                </p>
                <p>Chop up your romaine lettuce hearts roughly and add to large mixing bowl.</p>
                <p>
                  Slice your Calmex Wild Abalone into desired slices or chunks and add to mixing bowl along with freshly peeled parmesan cheese and croutons and toss the salad.
                </p>
                <p>
                  Pour your homemade caesar salad dressing and serve to your guests as a fresh, cold appetizer, and voila you are done!
                </p>
              </div>
              <footer className="entry-footer"></footer>
            </article>
          </div>
        </div>
        <Footer />
      </section>
    </div>
  );
};

export default WildAbaloneCaesarSalad;
