import React, { useState, useEffect } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { BrowserRouter as Router, Routes, Route, useLocation, Navigate } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';
import HomePage from './HomePage';
import AbalonePage from './AbalonePage';
import AusPinkLabelPage from './AusPinkLabelPage';
import TopShellPage from './TopShellPage';
import Sustainability from './Sustainability';
import RecipeListing from './RecipeListing';
import NotFound from './NotFound';

import Header from './Header';

import TermsConditions from './TermsConditions';

// Import all recipe components from the recipes folder
import * as RecipeComponents from './recipes';


const videoSources = {
  '/': {
    src: '/video-shortened.mp4',
    transition: { duration: 2 },
    loop: true,
  },
  '/products/abalone': {
    src: '/video2-shortened-b.mp4',
    transition: { duration: 2 },
    loop: false,
  },
  '/products/australian-pink-label': {
    src: '/video2-aus-pink-label-shortened.mp4',
    transition: { duration: 2 },
    loop: false,
  },
  '/products/top-shell': {
    src: '/video2-topshell-shortened-b.mp4',
    transition: { duration: 2 },
    loop: false,
  },
};

function AppWrapper() {
  return (
    <HelmetProvider>
      <Router>
        <App />
      </Router>
    </HelmetProvider>
  );
}

function App() {
  const location = useLocation();
  const [currentVideo, setCurrentVideo] = useState(videoSources[location.pathname]);

  useEffect(() => {
    setCurrentVideo(videoSources[location.pathname] || null);
  }, [location.pathname]);

  const handleVideoLoad = () => {
    const videoElement = document.querySelector('video');
    if (videoElement) {
      videoElement.play().catch(err => console.error("Error attempting to play video:", err));
    }
  };

  return (
    <div style={{ position: 'relative', height: '100vh' }}>
      <Header />
      <AnimatePresence initial={false}>
        {currentVideo && (
          <div style={{ width: '100%', height: '100%', objectFit: 'cover', position: 'absolute', zIndex: '-1' }}>
            <motion.video
              key={currentVideo.src}
              src={currentVideo.src}
              autoPlay
              loop={currentVideo.loop}
              playsInline
              muted
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={currentVideo.transition}
              onLoadedData={handleVideoLoad}
              style={{ width: '100%', height: '100%', objectFit: 'cover' }}
            />
          </div>
        )}
      </AnimatePresence>
      <Routes location={location} key={location.pathname}>
        <Route path="/" element={<HomePage />} />
        <Route path="/products/abalone" element={<AbalonePage />} />
        <Route path="/products/australian-pink-label" element={<AusPinkLabelPage />} />
        <Route path="/products/top-shell" element={<TopShellPage />} />
        <Route path="/sustainability" element={<Sustainability />} />
        <Route path="/terms-conditions" element={<TermsConditions />} />

        <Route path="/uncategorized/abalone-cream-stew" element={<RecipeComponents.AbaloneCreamStew />} />
        <Route path="/uncategorized/abalone-rice" element={<RecipeComponents.AbaloneRice />} />
        <Route path="/uncategorized/ice-cream-with-wild-abalone" element={<RecipeComponents.IceCreamWithWildAbalone />} />
        <Route path="/uncategorized/sauteed-abalone-shiitake" element={<RecipeComponents.SauteedAbaloneShiitake />} />
        <Route path="/uncategorized/spooky-spaghetti-squash-abalone-pastas" element={<RecipeComponents.SpookySpaghettiSquashAbalonePastas />} />
        <Route path="/uncategorized/wild-abalone-bagel" element={<RecipeComponents.WildAbaloneBagel />} />
        <Route path="/uncategorized/wild-abalone-caesar-salad" element={<RecipeComponents.WildAbaloneCaesarSalad />} />
        <Route path="/uncategorized/wild-abalone-gravy" element={<RecipeComponents.WildAbaloneGravy />} />
        <Route path="/uncategorized/wild-abalone-spring-roll" element={<RecipeComponents.WildAbaloneSpringRoll />} />
        
        {/* Recipes */}
        <Route path="/recipes" element={<RecipeListing />} />

        <Route path="/uncategorized" element={<Navigate to="/recipes" />} />
        <Route path="/our-process" element={<Navigate to="/sustainability" />} />


        <Route path="*" element={<NotFound />} />
      </Routes>
      <div id="videoPreload" style={{ display: 'none' }}></div> {/* Preload videos */}
    </div>
  );
}

export default AppWrapper;
