import React from "react";
import Footer from "../Footer";

const AbaloneRice = () => {
  return (
    <div className="pageWrapper">
      <section
        style={{ zIndex: 9999, width: "100%" }}
        className="mt-48 absolute bg-black"
      >
        <div className="bg-black text-white background-wheel">
          <div className="container mx-auto px-4 py-12 flex flex-wrap items-center justify-between">
            <div className="w-full">
              {/* Upper header pink text: RECIPE */}
              <h2 className="text-2xl font-bold text-pink-300 tracking-wider text-center">
                RECIPE
              </h2>
              <h1 className="mb-12 text-5xl/[58px] font-inter lg:text-6xl/[70px] font-light tracking-tight text-center">
                Abalone Rice
              </h1>
            </div>
            <article
              id="post-506"
              className="post-506 post type-post status-publish format-standard has-post-thumbnail hentry category-uncategorized font-inter"
            >
              <div className="post-thumbnail">
                <img
                  width="1080"
                  height="1350"
                  src="/Untitled-319.jpg"
                  className="attachment-post-thumbnail size-post-thumbnail wp-post-image"
                  alt=""
                  decoding="async"
                  loading="lazy"
                  srcSet="/Untitled-319.jpg 1080w, /Untitled-319-240x300.jpg 240w, /Untitled-319-819x1024.jpg 819w, /Untitled-319-768x960.jpg 768w"
                  sizes="(max-width: 1080px) 100vw, 1080px"
                />
              </div>
              <div className="entry-content">
                <figure className="wp-block-image size-large">
                  <img
                    decoding="async"
                    loading="lazy"
                    width="819"
                    height="1024"
                    src="/Untitled-319-819x1024.jpg"
                    alt=""
                    className="wp-image-508"
                    srcSet="/Untitled-319-819x1024.jpg 819w, /Untitled-319-240x300.jpg 240w, /Untitled-319-768x960.jpg 768w, /Untitled-319.jpg 1080w"
                    sizes="(max-width: 819px) 100vw, 819px"
                  />
                </figure>
                <p>
                  Looking for a quick, easy family meal to make on a cold Autumn night? Utilizing your rice cooker, you can make more than just plain white rice for the family. In Japan, takikomi gohan is seasoned rice that can be done all in the rice cooker itself! Add your flavorings and any other items in your rice cooker cooks the ingredients for you. It is full of flavor and many dishes can be recreated with this method. Feel free to get creative with it and impress your guests!
                </p>
                <figure className="wp-block-image size-large">
                  <img
                    decoding="async"
                    loading="lazy"
                    width="819"
                    height="1024"
                    src="/Untitled-318-819x1024.jpg"
                    alt=""
                    className="wp-image-507"
                    srcSet="/Untitled-318-819x1024.jpg 819w, /Untitled-318-240x300.jpg 240w, /Untitled-318-768x960.jpg 768w, /Untitled-318.jpg 1080w"
                    sizes="(max-width: 819px) 100vw, 819px"
                  />
                </figure>
                <p>Ingredients:</p>
                <p>1 Can Calmex Wild Abalone</p>
                <p>3-5 Cups of rice (adjust quantity based on how many people)</p>
                <p>1-2 Tablespoons of dashi powder (fish base)</p>
                <p>1 Package of premade hijiki</p>
                <p>1 Head of mushrooms</p>
                <p>1 Cup cooked edamame</p>
                <figure className="wp-block-image size-large">
                  <img
                    decoding="async"
                    loading="lazy"
                    width="819"
                    height="1024"
                    src="/Untitled-320-819x1024.jpg"
                    alt=""
                    className="wp-image-509"
                    srcSet="/Untitled-320-819x1024.jpg 819w, /Untitled-320-240x300.jpg 240w, /Untitled-320-768x960.jpg 768w, /Untitled-320.jpg 1080w"
                    sizes="(max-width: 819px) 100vw, 819px"
                  />
                </figure>
                <p>Directions:</p>
                <p>Add your desired amount of rice into your bowl and be sure to wash the grains at least 3 times and strain.</p>
                <p>Sprinkle 1-2 tablespoons of dashi powder into the rice cooker.</p>
                <p>Slice your Calmex Wild Abalone into bite-size slices and cut mushrooms into a decent size and add as well.</p>
                <p>Sprinkle your premade package of hijiki seaweed.</p>
                <p>Be sure to mix all contents around so the dashi seasoning disperses.</p>
                <p>Cook for desired time (40-an hour).</p>
                <p>Once done mix again before adding your cooked edamame into the cooker as well.</p>
                <p>Serve with your choice of soup or salad for a protein-packed comfort meal and enjoy!</p>
                <p>Be sure to add your own spices and ingredients to make this a special meal.</p>
              </div>
              <footer className="entry-footer"></footer>
            </article>
          </div>
        </div>
        <Footer />
      </section>
    </div>
  );
};

export default AbaloneRice;
