import React from "react";
import Footer from "../Footer";

const WildAbaloneBagel = () => {
  return (
    <div className="pageWrapper">
      <section
        style={{ zIndex: 9999, width: "100%" }}
        className="mt-48 absolute bg-black"
      >
        <div className="bg-black text-white background-wheel">
          <div className="container mx-auto px-4 py-12 flex flex-wrap items-center justify-between">
            <div className="w-full">
              {/* Upper header pink text: RECIPE */}
              <h2 className="text-2xl font-bold text-pink-300 tracking-wider text-center">
                RECIPE
              </h2>
              <h1 className="mb-12 text-5xl/[58px] font-inter lg:text-6xl/[70px] font-light tracking-tight text-center">
                Wild Abalone Bagel
              </h1>
            </div>
            <article
              id="post-469"
              className="post-469 post type-post status-publish format-standard hentry category-uncategorized tag-abalone tag-abalone-bagel tag-bagel-art tag-bagel-inspiration tag-bagel-recipe tag-seafood-bagel tag-wild-abalone tag-wild-abalone-bagel font-inter"
            >
              <div className="post-thumbnail"></div>
              <div className="entry-content">
                <figure className="wp-block-image size-large">
                  <img
                    decoding="async"
                    loading="lazy"
                    width="1024"
                    height="819"
                    src="/Untitled-161-1024x819.jpg"
                    alt=""
                    className="wp-image-470"
                    srcSet="/Untitled-161-1024x819.jpg 1024w, /Untitled-161-300x240.jpg 300w, /Untitled-161-768x614.jpg 768w, /Untitled-161.jpg 1350w"
                    sizes="(max-width: 1024px) 100vw, 1024px"
                  />
                </figure>
                <p>Everyone loves bagels!</p>
                <p>
                  Bagels can be sweet, savory, chewy, crunchy and everything in between. When topped with some delicious cream cheese, they can even have a nice, creamy texture in every bite. Throughout the States, bagels have become a breakfast favorite because they can be dressed up to be savory, sweet, or even spicy! If you are not a fan of bagels for breakfast, you can make them into sandwiches for a satisfying lunch on the go! Who says bagels can only be enjoyed during breakfast?
                </p>
                <p>
                  Here in the States (where Calmex is headquartered), we are lucky enough to live nearby many local bagel shops in Southern California. Many of them are small, family-owned businesses that have perfected their bagel technique and have been around for many years due to their popularity and high-quality! We love supporting local businesses and try to buy as much as we can from local, family-owned shops. We sourced our bagels for this week’s recipe from{" "}
                  <a href="https://www.shirleysbagels.com/">Shirley’s Bagels</a>. Shirley’s is local to Orange County and has 3 locations in California at the moment. Shirley’s is so popular that they have supplied bagels to the local Ritz-Carlton, Hilton, and other luxury establishments! They even have supplied to local universities and country clubs! They hand-bake their bagels fresh every morning and offer a variety of toppings and cream cheeses to customize your order!
                </p>
                <p>
                  We are sure local bakeries in Asia offer bagels at specialty locations, you just have to find it! If you have tried your best to look for bagels in your area but cannot find them, that’s ok! Try our abalone recipe with freshly baked sourdough bread or on a thinly sliced french baguette to make up for it.
                </p>
                <figure className="wp-block-image size-large">
                  <img
                    decoding="async"
                    loading="lazy"
                    width="1024"
                    height="819"
                    src="/Untitled-162-1024x819.jpg"
                    alt=""
                    className="wp-image-471"
                    srcSet="/Untitled-162-1024x819.jpg 1024w, /Untitled-162-300x240.jpg 300w, /Untitled-162-768x614.jpg 768w, /Untitled-162.jpg 1350w"
                    sizes="(max-width: 1024px) 100vw, 1024px"
                  />
                </figure>
                <p><strong>Ingredients</strong>:</p>
                <p>1 Can Calmex Wild Abalone (use Pre-Sliced for added convenience)</p>
                <p>1-3 Bagels sliced in half (1 half per person)</p>
                <p>1 Cream cheese</p>
                <p>1 Tomato sliced</p>
                <p>1 Avocado sliced</p>
                <p>1 teaspoon of lemon pepper</p>
                <p>1 Handful of microgreens or sprouts</p>
                <p>1 Tablespoon of capers (optional)</p>
                <figure className="wp-block-image size-large">
                  <img
                    decoding="async"
                    loading="lazy"
                    width="1024"
                    height="819"
                    src="/Untitled-163-1024x819.jpg"
                    alt=""
                    className="wp-image-472"
                    srcSet="/Untitled-163-1024x819.jpg 1024w, /Untitled-163-300x240.jpg 300w, /Untitled-163-768x614.jpg 768w, /Untitled-163.jpg 1350w"
                    sizes="(max-width: 1024px) 100vw, 1024px"
                  />
                </figure>
                <p><strong>Directions</strong>:</p>
                <p>
                  Slice your fresh bagels in half and toast them slightly. Once toasted to your favorite level of toast-iness, set aside to cool. Tip: Don’t spread your cream cheese when your bagel is hot or else it may get too melted!
                </p>
                <p>
                  While you wait for you bagels to toast, thinly slice your Calmex Wild Abalone into bite-size pieces. Enough for about 4-5 slices per bagel.
                </p>
                <p>(If your Calmex Wild Abalone is pre-sliced, there is no need to slice them!)</p>
                <p>Thinly slice your tomato and avocado into desired thinness.</p>
                <p>Once your bagel is cooled down spread a generous amount of cream cheese.</p>
                <p>Add your tomato slice first, then layer a few avocado slices on top. Add your wild abalone topping on top of the avocado.</p>
                <p>
                  Sprinkle your lemon pepper seasoning, squeeze lemon juice, and your microgreens/sprouts on top to impress your guests!
                </p>
                <p>Be creative with your bagel art and feel free to tag us in any homemade abalone recipes!</p>
              </div>
              <footer className="entry-footer">
                <span className="tags-links">
                  Tagged{" "}
                  <a href="https://calmex.com/tag/abalone/" rel="tag">
                    Abalone
                  </a>
                  ,{" "}
                  <a href="https://calmex.com/tag/abalone-bagel/" rel="tag">
                    Abalone Bagel
                  </a>
                  ,{" "}
                  <a href="https://calmex.com/tag/bagel-art/" rel="tag">
                    Bagel Art
                  </a>
                  ,{" "}
                  <a href="https://calmex.com/tag/bagel-inspiration/" rel="tag">
                    Bagel Inspiration
                  </a>
                  ,{" "}
                  <a href="https://calmex.com/tag/bagel-recipe/" rel="tag">
                    Bagel Recipe
                  </a>
                  ,{" "}
                  <a href="https://calmex.com/tag/seafood-bagel/" rel="tag">
                    Seafood Bagel
                  </a>
                  ,{" "}
                  <a href="https://calmex.com/tag/wild-abalone/" rel="tag">
                    Wild Abalone
                  </a>
                  ,{" "}
                  <a href="https://calmex.com/tag/wild-abalone-bagel/" rel="tag">
                    Wild Abalone Bagel
                  </a>
                </span>
              </footer>
            </article>
          </div>
        </div>
        <Footer />
      </section>
    </div>
  );
};

export default WildAbaloneBagel;
