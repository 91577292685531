import React from "react";
import { Helmet } from "react-helmet-async";
import Footer from "./Footer";

const NotFound = () => {
  return (
    <div className="pageWrapper">
      <Helmet>
        <title>404 - Page Not Found | Calmex</title>
      </Helmet>
      <section
        style={{ zIndex: 9999, width: "100%" }}
        className="mt-48 absolute bg-black"
      >
        <div className="bg-black text-white md:pb-24">
          <div className="container mx-auto px-4 py-12">
            <div className="w-full">
              <h1 className="mb-12 text-5xl/[58px] font-inter lg:text-6xl/[70px] font-light tracking-tight text-center">
                Hmm, we can't find that page.
              </h1>
              {/* Try <heading back home> */}
              <div className="text-center">
                <a href="/" className="text-2xl font-bold hover:text-pink-300 transition-colors underline tracking-wider">
                  Go to homepage
                </a>
                </div>
            </div>
          </div>
        </div>
        <Footer />
      </section>
    </div>
  );
};

export default NotFound;
