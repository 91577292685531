import React from "react";
import Footer from "../Footer";

const SpookySpaghettiSquashAbalonePastas = () => {
  return (
    <div className="pageWrapper">
      <section
        style={{ zIndex: 9999, width: "100%" }}
        className="mt-48 absolute bg-black"
      >
        <div className="bg-black text-white background-wheel">
          <div className="container mx-auto px-4 py-12 flex flex-wrap items-center justify-between">
            <div className="w-full">
              {/* Upper header pink text: RECIPE */}
              <h2 className="text-2xl font-bold text-pink-300 tracking-wider text-center">
                RECIPE
              </h2>
              <h1 className="mb-12 text-5xl/[58px] font-inter lg:text-6xl/[70px] font-light tracking-tight text-center">
                Spooky Spaghetti Squash Abalone Pastas
              </h1>
            </div>
            <article
              id="post-489"
              className="post-489 post type-post status-publish format-standard hentry category-uncategorized font-inter"
            >
              <div className="post-thumbnail"></div>
              <div className="entry-content">
                <figure className="wp-block-image size-large">
                  <img
                    decoding="async"
                    loading="lazy"
                    width="819"
                    height="1024"
                    src="/IMG_0869-819x1024.jpg"
                    alt=""
                    className="wp-image-495"
                    srcSet="/IMG_0869-819x1024.jpg 819w, /IMG_0869-240x300.jpg 240w, /IMG_0869-768x960.jpg 768w, /IMG_0869.jpg 1080w"
                    sizes="(max-width: 819px) 100vw, 819px"
                  />
                </figure>
                <p>
                  In the States, Halloween is the fun part of the year where all spooky things are celebrated! Everything from movies to family-fun attractions (and even food) are transformed into scary, good fun. As the leaves start the change, Fall recipes make a seasonal appearance with all types of squash, nuts, and stone fruit. We wanted to make this month’s Fall recipe with a healthy spaghetti substitute if you are looking for a keto-packed diet full of protein.
                </p>
                <p>
                  We wanted to make 2 variations of the recipe so we made 2 different sauces to pair with our wild abalone and this savory squash. Don’t forget it can also be transformed into a spooky dinner recipe too! Cut your abalone into thin, round slices shaped like ghosts with seaweed for eyes and mouth. Or cut abalone into long finger-length pieces to make them look like severed fingers in tomato sauce. However you make it, it will be sure to impress your guests at the haunted dinner table.
                </p>
                <figure className="wp-block-image size-large">
                  <img
                    decoding="async"
                    loading="lazy"
                    width="819"
                    height="1024"
                    src="/Untitled-310-819x1024.jpg"
                    alt=""
                    className="wp-image-491"
                    srcSet="/Untitled-310-819x1024.jpg 819w, /Untitled-310-240x300.jpg 240w, /Untitled-310-768x960.jpg 768w, /Untitled-310.jpg 1080w"
                    sizes="(max-width: 819px) 100vw, 819px"
                  />
                </figure>
                <p>Ingredients:</p>
                <p>1 Can Calmex Wild Abalone</p>
                <p>1 Whole spaghetti squash</p>
                <p>1 Can tomato sauce</p>
                <p>1 Package of green basil pesto</p>
                <p>1-2 Tablespoons of olive oil</p>
                <p>2-3 Garlic cloves</p>
                <p>1 Package of parmesan cheese</p>
                <p>Juice of lemon</p>
                <p>Salt + Pepper</p>
                <p>Dried Parsley</p>
                <figure className="wp-block-image size-large">
                  <img
                    decoding="async"
                    loading="lazy"
                    width="819"
                    height="1024"
                    src="/Untitled-311-819x1024.jpg"
                    alt=""
                    className="wp-image-493"
                    srcSet="/Untitled-311-819x1024.jpg 819w, /Untitled-311-240x300.jpg 240w, /Untitled-311-768x960.jpg 768w, /Untitled-311.jpg 1080w"
                    sizes="(max-width: 819px) 100vw, 819px"
                  />
                </figure>
                <p>Directions:</p>
                <p>Carefully cut your spaghetti squash in half, lengthwise. Be sure to poke holes with a fork for even baking throughout.</p>
                <p>Generously oil the squash with olive oil and season with salt and pepper and lay them facing upright.</p>
                <p>Leave in the preheated oven at 375 for 40 minutes and flip the squash downside halfway through the baking time.</p>
                <p>Meanwhile, slice up your Calmex Wild Abalone into your desired shapes. They can be spooky shapes like ghosts, tombstones, fingers etc. (Pro Tip: use a small piece of seaweed to make faces or write words)</p>
                <p>In a headed pan sautee garlic cloves with butter or olive oil in a pan. Add your tomato sauce to simmer.</p>
                <p>Optional: You can heat up your pesto sauce if you want!</p>
                <p>Once your spaghetti squash is completely baked you should be able to scrape the inside with a fork to create a strand-like spaghetti texture.</p>
                <p>Add your sauces into the 2 divided halves of your spaghetti squash. One can be pesto, the other tomato sauce. Mix the contents together until well combined. Feel free to sprinkle some parmesan cheese on both if you like!</p>
                <p>Top your squash with your decorated slices of wild abalone. Add any other toppings you would like as well like lemon juice on the pesto or tabasco on the tomato sauce.</p>
                <p>Have fun and be creative with your dish! Happy Halloween</p>
                <figure className="wp-block-image size-large">
                  <img
                    decoding="async"
                    loading="lazy"
                    width="819"
                    height="1024"
                    src="/Untitled-312-819x1024.jpg"
                    alt=""
                    className="wp-image-494"
                    srcSet="/Untitled-312-819x1024.jpg 819w, /Untitled-312-240x300.jpg 240w, /Untitled-312-768x960.jpg 768w, /Untitled-312.jpg 1080w"
                    sizes="(max-width: 819px) 100vw, 819px"
                  />
                </figure>
              </div>
              <footer className="entry-footer"></footer>
            </article>
          </div>
        </div>
        <Footer />
      </section>
    </div>
  );
};

export default SpookySpaghettiSquashAbalonePastas;
