import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';

const textVariant = {
  rest: {
    y: 0,
    transition: {
      type: 'spring',
      stiffness: 260,
      damping: 40,
    },
  },
  hover: {
    y: 18,
    transition: {
      type: 'spring',
      stiffness: 260,
      damping: 40,
    },
  },
};

const canVariant = {
  hidden: { 
    y: 0, 
    opacity: 0,
    transition: {
      duration: 0.60,
    },
},
  visible: {
    y: -8,
    opacity: 1,
    transition: {
      duration: 0.60,
    },
  },
};

const MenuItem = ({ text, imgSrc }) => {
  const [isHovered, setIsHovered] = React.useState(false);

  return (
    <div className="menu-item" 
    onMouseEnter={() => setIsHovered(true)} 
    onMouseLeave={() => setIsHovered(false)}
    >
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <AnimatePresence>
        {isHovered && (
          <motion.img
            src={imgSrc}
            alt={text}
            className="can-image"
            variants={canVariant}
            initial="hidden"
            animate="visible"
            exit="hidden"
          />
        )}
      </AnimatePresence>
      <motion.div className="text" variants={textVariant} initial="rest" whileHover="hover" animate={isHovered ? "hover" : "rest"}>
        {text}
      </motion.div>
      </div>
    </div>
  );
};

export default MenuItem;
